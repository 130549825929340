import {
  AdminDollarInput2,
  AdminInput,
  AdminPhoneInput,
  AdminSelect,
  Button,
  Div,
  ErrorToast,
  Modal,
  SuccessToast,
  api,
} from '@vgrubs/components';
import { useCallback, useEffect, useState } from 'react';

export const UsersAndAccessEditModal = ({
  modalIsOpen,
  setModalIsOpen,
  setLoading,
  setUsers,
  setUserDetailsModal,
  isAgent = false,
  agentAmountPerLocation,
  setAgentDetails,
}) => {
  const user = modalIsOpen;

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [role, setRole] = useState('');
  const [amountPerLocation, setAmountPerLocation] = useState(0);
  // const [achCustomerId, setAchCustomerId] = useState('');

  const showRolePicker = role === 'admin' || role === 'support';

  const roles = [
    { name: 'Admin', value: 'admin' },
    { name: 'Support', value: 'support' },
  ];

  const typeOptions = (
    <optgroup label="Select Role">
      {roles.map((r, i) => (
        <option value={r.value} key={i}>
          {r.name}
        </option>
      ))}
    </optgroup>
  );

  async function handleUpdateAgentFlatAmount() {
    try {
      setLoading(true);

      const { data } = await api.put(
        `/v1/agents/${user?.id}/update-flat-amount`,
        { amountPerLocation }
      );

      setAgentDetails((prev) => {
        return { ...prev, agent: { ...prev.agent, amountPerLocation } };
      });

      // SuccessToast(data.message);
    } catch (error) {
      ErrorToast(error?.response?.data?.message);
    } finally {
      setLoading(false);
    }
  }

  async function handleVerifyLocation() {
    if (!firstName && !lastName && !email && !phoneNumber && !role) {
      ErrorToast('Please fill in all information');
      return;
    }

    const body = {
      firstName,
      lastName,
      phoneNumber,
      role,
      email,
      // achCustomerId,
    };

    try {
      setLoading(true);
      const { data } = await api.patch(`/v1/users/${user.id}`, body);
      SuccessToast(data.message);

      if (!isAgent) {
        setUserDetailsModal(data.data);
        setUsers((prevState) => {
          // Map over the previous state array
          return prevState.map((item) => {
            // If the ID matches, update the properties
            if (item.user.id === user.id) {
              return { ...item, user: data.data };
            }
            // If the ID doesn't match, return the original item
            return item;
          });
        });
      } else {
        if (
          isAgent &&
          amountPerLocation &&
          agentAmountPerLocation !== amountPerLocation
        ) {
          setAgentDetails((prev) => {
            return { ...prev, user: data.data };
          });
          handleUpdateAgentFlatAmount();
        }
      }

      setModalIsOpen(false);
    } catch (error) {
      ErrorToast(error?.response?.data?.message);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    if (modalIsOpen) {
      setFirstName(user.firstName);
      setLastName(user.lastName);
      setEmail(user.email);
      setPhoneNumber(user.phoneNumber);
      setRole(user.role);
      if (isAgent) {
        setAmountPerLocation(agentAmountPerLocation);
      }
    }
  }, [modalIsOpen]);

  const handleFlatAmount = useCallback((val) => {
    setAmountPerLocation(val);
  }, []);

  return (
    <Modal
      isOpen={modalIsOpen}
      setIsOpen={setModalIsOpen}
      logo={false}
      header="Edit User"
      zIndex={110}
    >
      <Div $flex $column $gap="20px" $gapSM="16px">
        <AdminInput
          label="First Name"
          placeholder="Enter here"
          value={firstName}
          onChange={(e) => setFirstName(e.target.value)}
          // $textCenter
        />
        <AdminInput
          label="Last Name"
          placeholder="Enter here"
          value={lastName}
          onChange={(e) => setLastName(e.target.value)}
          // $textCenter
        />
        <AdminInput
          label="Email"
          placeholder="Enter here"
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          // $textCenter
        />

        <AdminPhoneInput
          value={phoneNumber}
          onChange={setPhoneNumber}
          label="Phone"
        />

        {isAgent && (
          <AdminDollarInput2
            label="Flat Amount Per Location"
            onValueChange={handleFlatAmount}
            value={amountPerLocation}
            $textCenter
          />
        )}

        {showRolePicker && (
          <AdminSelect
            value={role}
            options={typeOptions}
            onChange={({ target }) => setRole(target.value)}
            placeholder="Select location"
            label="Role"
            chevron
          />
        )}
      </Div>

      <Button
        $selected
        $w="100%"
        $mt="50"
        $mtSM="30"
        onClick={handleVerifyLocation}
      >
        Edit
      </Button>
    </Modal>
  );
};
