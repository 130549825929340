import {
  ADMIN_LOCATIONS_DETAILS,
  AdminDatePicker,
  AdminHeader,
  AdminSpinner,
  AnimatedDiv,
  ArrowRed,
  Button,
  Disclaimer,
  Div,
  EditPenIconRed,
  ErrorToast,
  H3,
  H5,
  HeaderIconWrapper,
  Icon,
  Logo,
  NoteIconRed,
  OrderInfoIcon,
  P,
  PlusIcon,
  Separator,
  SuccessToast,
  VDriveIcon,
  V_DRIVE_STATUS,
  api,
  lightTheme,
  setTimeTo,
  showStringIfNotNull,
  useAuth,
} from '@vgrubs/components';
import { useEffect, useState } from 'react';
import { Link, useMatch, useNavigate } from 'react-router-dom';
import { LocationComponent } from '../Dashboard/LocationComponent';
import { BankEdit } from './BankEdit';
import { BrandAddModal } from './BrandAdd';
import { BrandAddSuccessModal } from './BrandAddSuccess';
import { BrandComponent } from './BrandComponent';
import { BrandNoteModal } from './BrandNoteModal';
import { ImageWithDownload } from './ImageInput';
import { LocationEdit } from './LocationEdit';
import { GridBrands, GridStats, ProviderStatus } from './LocationStyled';
import { MenuDetails } from './ManuDetails';
import { providerStatus, statusColor, statusName } from './helpers';
import { DeleteLocationBrandModal } from './DeleteLocationBrandModal';
import { UsersAndAccessDetailsModal } from '../UsersAndAccess/UsersAndAccessDetails';
import { AllProvidersModalComponent } from '../Dashboard/AllProvidersModalComponent';
import { TemplateBrandAdd } from './TemplateBrandAdd';
import {
  ChangeLocationAddress,
  ChangeLocationAddressConfirm,
} from './ChangeLocationAddress';
import { Earnings } from './earnings/Earnings';
import { Services } from './services/Services';

export const LocationDetails = () => {
  const navigate = useNavigate();

  const today = new Date();
  const [loading, setLoading] = useState(false);
  const id = useMatch(ADMIN_LOCATIONS_DETAILS).params.id;
  const [editLocationModal, setEditLocationModal] = useState(false);
  const [editBankModal, setEditBankModal] = useState(false);
  const [addBrandModal, setAddBrandModal] = useState(false);
  const [addBrandSuccessModal, setAddBrandSuccessModal] = useState(false);
  const [menuModal, setMenuModal] = useState(false);
  const [noteModal, setNoteModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [statuses, setStatuses] = useState([]);
  const [data, setData] = useState(null);
  const [date, setDate] = useState([today, today]);
  const [dateInput, setDateInput] = useState([today, today]);
  const [userDetailsModal, setUserDetailsModal] = useState(null);
  const [allProvidersModal, setAllProvidersModal] = useState(null);
  const [brandTemplates, setBrandTemplates] = useState(false);

  const [changeAddressModal, setChangeAddressModal] = useState(null);
  const [confirmAddressModal, setConfirmAddressModal] = useState(null);

  const { role } = useAuth((state) => state.user);

  const dateFrom = new Intl.DateTimeFormat('en-US', {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  }).format(date[0]);

  const dateTo = new Intl.DateTimeFormat('en-US', {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  }).format(date[1]);

  const handleDateChange = (date) => {
    setDateInput(date);
  };

  const handleDateApply = () => {
    if (dateInput.length < 2) {
      setDate([dateInput[0], dateInput[0]]);
      setDateInput([dateInput[0], dateInput[0]]);
    } else {
      setDate(dateInput);
    }
  };

  async function getLocationDetails() {
    const queryString = new URLSearchParams({
      from: setTimeTo(date[0], 'start'),
      to: setTimeTo(date[1], 'end'),
    }).toString();

    try {
      setLoading(true);
      const { data } = await api.get(`/v1/locations/${id}?${queryString}`);
      setData(data.data);
      setLoading(false);
    } catch (error) {
      ErrorToast(error?.response?.data?.message);
      setLoading(false);
    }
  }

  async function syncProviders() {
    try {
      setLoading(true);
      const { data } = await api.post(`/v1/locations/sync-providers`, {
        locationId: id,
      });
      SuccessToast(data.message);
      setLoading(false);
    } catch (error) {
      ErrorToast(error?.response?.data?.message);
      setLoading(false);
    }
  }

  // async function syncStores() {
  //   try {
  //     setLoading(true);
  //     const { data } = await api.post(`/v1/locations/sync-stores`, {
  //       locationId: id,
  //     });
  //     getLocationDetails();
  //     SuccessToast(data.message);
  //     setLoading(false);
  //   } catch (error) {
  //     ErrorToast(error?.response?.data?.message);
  //     setLoading(false);
  //   }
  // }

  async function getProvidersStatuses() {
    try {
      setLoading(true);
      const statusesData = await api.get(
        `/v1/locations/${id}/providers-statuses`
      );

      setStatuses(statusesData.data.data);

      setLoading(false);
    } catch (error) {
      ErrorToast(error?.response?.data?.message);
      setLoading(false);
    }
  }

  useEffect(() => {
    getLocationDetails();
    getProvidersStatuses();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date]);

  const onAddBrandSuccess = (brand) => {
    setAddBrandModal(false);
    setAddBrandSuccessModal(brand);
  };

  const onAddBrandWithTemplateSuccess = (brand) => {
    setBrandTemplates(false);
    setAddBrandSuccessModal(brand);
  };

  const onCreateNonVGrubsBrand = (brand) => {
    setBrandTemplates(false);
    setAddBrandModal(true);
  };

  const hasOffline = statuses.some(
    (item) => item.status === providerStatus.OFFLINE
  );

  const hasUnknown = statuses.some(
    (item) => item.status === providerStatus.UNKNOWN
  );

  const hasOnline = statuses.some(
    (item) => item.status === providerStatus.ONLINE
  );

  const ProviderStatusComponent = ({ status }) => {
    return (
      <ProviderStatus $color={statusColor(status)}>
        <Disclaimer $m="0" $color={lightTheme.white}>
          {statusName(status)}
        </Disclaimer>
      </ProviderStatus>
    );
  };

  const RenderStatus = () => {
    if (hasUnknown) {
      return <ProviderStatusComponent status={providerStatus.UNKNOWN} />;
    } else if (hasOffline) {
      return <ProviderStatusComponent status={providerStatus.OFFLINE} />;
    } else if (hasOnline) {
      return <ProviderStatusComponent status={providerStatus.ONLINE} />;
    } else return null;
  };

  const locationAddress = `${showStringIfNotNull(
    data?.address,
    true
  )} ${showStringIfNotNull(data?.city, true)} ${showStringIfNotNull(
    data?.country,
    true
  )} ${showStringIfNotNull(data?.state, true)} ${showStringIfNotNull(
    data?.zipCode
  )}`;

  const ActionButtons = () => (
    <Div $flex $column $gap="16px" $widthSM="100%">
      <Link to={`/menu/${id}/location/0/brand/delivery`}>
        <Button $w="100%" $wSM="100%" $selected>
          View Menu
        </Button>
      </Link>

      <Link to={`/locations/manage-providers/${id}`}>
        <Button $w="100%" $wSM="100%" $gray $selected>
          Manage Providers
        </Button>
      </Link>

      <Div $flex $gap="16px" $columnSM>
        <Button $wSM="100%" $gray onClick={syncProviders}>
          Sync Providers
        </Button>
        {(role === 'admin' || role === 'support') && (
          <Button $wSM="100%" onClick={() => setDeleteModal(id)}>
            Delete Location
          </Button>
        )}
      </Div>
    </Div>
  );

  const handleVDriveOnClick = (status) => {
    console.log(status);
    switch (status) {
      case V_DRIVE_STATUS.active:
        return navigate(`/locations/v-drive/${id}`);
      case V_DRIVE_STATUS.inactive:
        // return ErrorToast(
        //   'vDrive is not connected. Partner needs to activate it from the restaurant app.'
        // );
        return navigate(`/locations/v-drive/onboarding/${id}?step=0`, {
          state: data,
        });
      case V_DRIVE_STATUS.inProgress:
        // return ErrorToast(
        //   'vDrive is under verification progress. Visit Verifications section to activate it.'
        // );
        return navigate(`/locations/v-drive/onboarding/${id}?step=3`, {
          state: data,
        });
      case V_DRIVE_STATUS.rejected:
        // return ErrorToast(
        //   'vDrive has been rejected by vGrubs. To activate it, partner needs to activate it from the restaurant app.'
        // );
        return navigate(`/locations/v-drive/onboarding/${id}?step=0`, {
          state: data,
        });

      case null:
        // return ErrorToast(
        //   'vDrive is not connected. Partner needs to activate it from the restaurant app.'
        // );
        return navigate(`/locations/v-drive/onboarding/${id}?step=0`, {
          state: data,
        });

      default:
        break;
    }

    navigate(`/locations/v-drive/${id}`, { state: data });
  };

  return (
    <>
      {loading && <AdminSpinner />}
      <AdminHeader title="Location Details">
        <Div $flex $alignCenter $gap="32px" $gapSM="16px">
          <HeaderIconWrapper onClick={() => setNoteModal(true)}>
            <NoteIconRed />
          </HeaderIconWrapper>
          <HeaderIconWrapper onClick={() => setEditLocationModal(true)}>
            <EditPenIconRed />
          </HeaderIconWrapper>
          <HeaderIconWrapper
            onClick={() => handleVDriveOnClick(data?.vDriveStatus)}
          >
            <VDriveIcon />
          </HeaderIconWrapper>
        </Div>
      </AdminHeader>
      {data && (
        <AnimatedDiv>
          <Div $m="40px 0" $flex $alignCenter $between $columnSM $gap="30px">
            <Div $flex $alignCenter $gap="30px" $gapSM="16px" $widthSM="100%">
              <Logo width="125" height="125" />
              <Div>
                <H3 $mb="8" $color={lightTheme.primary}>
                  {data.name}
                </H3>
                <P $mb="8">{locationAddress}</P>
                <P $mb="8">{data.phone}</P>
                <RenderStatus />
              </Div>
            </Div>
            <Div $desktop>
              <ActionButtons />
            </Div>
          </Div>
          <Div $m="24px 0" $flex $gap="40px" $gapSM="20px" $columnSM>
            <OwnerComponent
              title={'Owner'}
              owner={data.owner}
              onClick={() => setUserDetailsModal(data.owner)}
            />
            <Earnings
              referral={data.referral}
              setLoading={setLoading}
              id={id}
            />
          </Div>
          <Div $m="24px 0" $flex $gap="40px" $gapSM="20px" $columnSM>
            <Div $width="50%" $widthSM="100%" $flex>
              <BracketPercentageComponent
                bracketPercentage={data.bracketPercentage}
              />
            </Div>
            <Div $width="50%" $widthSM="100%">
              <Services
                setLoading={setLoading}
                id={id}
                salesConfig={data.salesConfig}
                setData={setData}
                componentText={`Services that client is currently using`}
              />
            </Div>
          </Div>
          <Div $m="24px 0" $flex $gap="40px" $gapSM="20px" $columnSM></Div>
          <Div $flex $between $mb="16" $alignCenter>
            <H5 $m="0">Overview</H5>

            <AdminDatePicker
              mode="range"
              value={dateInput}
              onChange={handleDateChange}
              click={handleDateApply}
              dateFrom={dateFrom}
              dateTo={dateTo}
            />
          </Div>
          <GridStats>
            <LocationComponent
              title="Total Sales"
              data={data?.salesStatistics?.total}
              setAllProvidersModal={setAllProvidersModal}
            />
            <LocationComponent
              title="Total Orders"
              data={data?.salesStatistics?.count}
              setAllProvidersModal={setAllProvidersModal}
            />
            <LocationComponent
              title="Total Taxes"
              data={data?.salesStatistics?.tax}
              setAllProvidersModal={setAllProvidersModal}
            />
            <LocationComponent
              title="Total Recovered"
              data={data?.salesStatistics?.recovered}
              setAllProvidersModal={setAllProvidersModal}
            />
            <LocationComponent
              title="Total Canceled"
              data={data?.salesStatistics?.cancelled}
              setAllProvidersModal={setAllProvidersModal}
            />
            <LocationComponent
              title="Payout to Partner"
              data={data?.earnings}
              setAllProvidersModal={setAllProvidersModal}
            />
          </GridStats>

          <Div $mb="16" $flex $between $alignCenter>
            <H5 $m="0">Brands</H5>
            <Icon $pointer onClick={() => setBrandTemplates(true)}>
              <PlusIcon />
            </Icon>
          </Div>

          {data.brands.length === 0 ? (
            <P $m="40px 0" $center>
              No brands
            </P>
          ) : (
            <GridBrands>
              {data.brands.map((brand, i) => {
                return <BrandComponent key={i} brand={brand} />;
              })}
            </GridBrands>
          )}

          <Div $mobile $m="20px 0">
            <ActionButtons />
          </Div>

          <Div $mb="16" $flex $between $alignCenter>
            <H5 $m="0">Bank Details</H5>
            <Icon $pointer onClick={() => setEditBankModal(true)}>
              <EditPenIconRed />
            </Icon>
          </Div>

          <Div $flex $columnSM $gap="40px" $gapSM="20px" $width="100%" $mb="40">
            <Div $flex $between $box $p="16px" $width="50%" $widthSM="100%">
              <P $m="0">Last 4 EIN:</P>
              <P $m="0">{data.configuration.einLast4}</P>
            </Div>
            <Div $flex $between $box $p="16px" $width="50%" $widthSM="100%">
              <P $m="0">Last 4 Bank:</P>
              <P $m="0">{data.configuration.bankAccountLast4}</P>
            </Div>
          </Div>

          <H5 $mb="16">Voided Check</H5>

          <Div
            $flex
            $columnSM
            $between
            $wrap
            $gap="24px"
            $gapSM="20px"
            $width="100%"
          >
            {data.images.map((img, i) => {
              return (
                <Div key={i} $width="30%" $widthSM="100%">
                  <ImageWithDownload src={img.url} header={'Voided Check'} />
                </Div>
              );
            })}
          </Div>

          <BrandAddModal
            setLoading={setLoading}
            modalIsOpen={addBrandModal}
            setModalIsOpen={setAddBrandModal}
            onAddBrandSuccess={onAddBrandSuccess}
            setData={setData}
            data={data}
          />

          <TemplateBrandAdd
            setLoading={setLoading}
            modalIsOpen={brandTemplates}
            setModalIsOpen={setBrandTemplates}
            onAddBrandSuccess={onAddBrandWithTemplateSuccess}
            setData={setData}
            data={data}
            brandTemplates={brandTemplates}
            onCreateNonVGrubsBrand={onCreateNonVGrubsBrand}
          />

          <BrandAddSuccessModal
            modalIsOpen={addBrandSuccessModal}
            setModalIsOpen={setAddBrandSuccessModal}
          />
          <MenuDetails
            modalIsOpen={menuModal}
            setModalIsOpen={setMenuModal}
            id={id}
            setLoading={setLoading}
            name={data.name}
            type="locations"
          />
          <BrandNoteModal
            setModalIsOpen={setNoteModal}
            modalIsOpen={noteModal}
            setLoading={setLoading}
            setData={setData}
            data={data}
            pathPrefix="locations"
          />
        </AnimatedDiv>
      )}

      <LocationEdit
        setLoading={setLoading}
        modalIsOpen={editLocationModal}
        setModalIsOpen={setEditLocationModal}
        setData={setData}
        data={data}
        setChangeAddressModal={setChangeAddressModal}
      />
      <BankEdit
        setLoading={setLoading}
        modalIsOpen={editBankModal}
        setModalIsOpen={setEditBankModal}
        setData={setData}
        data={data}
      />
      <DeleteLocationBrandModal
        isOpen={deleteModal}
        setIsOpen={setDeleteModal}
        setLoading={setLoading}
        type={'locations'}
      />
      <UsersAndAccessDetailsModal
        modalIsOpen={userDetailsModal}
        setModalIsOpen={setUserDetailsModal}
        noActions
        callGetUser
      />
      <AllProvidersModalComponent
        allProvidersModal={allProvidersModal}
        setAllProvidersModal={setAllProvidersModal}
      />
      <ChangeLocationAddress
        modalIsOpen={changeAddressModal}
        setModalIsOpen={setChangeAddressModal}
        setLoading={setLoading}
        setConfirmAddressModal={setConfirmAddressModal}
      />

      <ChangeLocationAddressConfirm
        modalIsOpen={confirmAddressModal}
        setModalIsOpen={setConfirmAddressModal}
        setLoading={setLoading}
        setData={setData}
      />
    </>
  );
};

const OwnerComponent = ({ owner, title, onClick }) => {
  return (
    <Div $width="50%" $widthSM="100%">
      <Separator />
      <Div $p="16px 0" $gap="20px" $flex $alignCenter $between>
        <Div>
          <P $mb="4">{title}</P>
          <H5 $mb="0">
            {owner.firstName} {owner.lastName}
          </H5>
        </Div>
        {onClick && (
          <Icon $pointer onClick={onClick}>
            <ArrowRed />
          </Icon>
        )}
      </Div>
      <Separator />
    </Div>
  );
};

const BracketPercentageComponent = ({ bracketPercentage }) => {
  return (
    <Div
      $p="12px 16px"
      $gap="20px"
      $flex
      $alignCenter
      $between
      $box
      style={{ background: 'none', boxShadow: 'none' }}
    >
      <Div>
        <P $mb="4">
          Bracket Percentage: <span>{bracketPercentage}%</span>
        </P>
      </Div>
      <Icon>
        <OrderInfoIcon />
      </Icon>
    </Div>
  );
};
