import {
  Disclaimer,
  Div,
  EditPenIconDarkGray,
  Icon,
  P,
  centsToDollars,
  formatter,
  lightTheme,
} from '@vgrubs/components';
import { useState } from 'react';
import { EarningPlanStyled, Pill } from './Earning.styled';
import { EditEarningPlan } from './EditEarningPlan';
import { TextWithTooltipIcon } from '../../Transactions/TransactonsStyled';

const EarningPlanStatus = ({ active, expired }) => {
  if (active || expired) {
    return (
      <Pill $expired={expired}>
        <Disclaimer $m="0" $color={lightTheme.white}>
          {expired ? 'Expired' : 'Active'}
        </Disclaimer>
      </Pill>
    );
  } else return null;
};

export const EarningPlan = ({ plan, setEarningPlans, index }) => {
  const { active, expired } = plan;
  const isLifetime = plan.year === null;
  const amount = formatter.format(centsToDollars(plan?.amount));

  const [editModal, setEditModal] = useState();

  const renderPlanName = () => {
    if (isLifetime) {
      return 'Lifetime Plan';
    } else return `Year ${plan.year} Plan`;
  };

  const handleSetAmount = (amount) => {
    setEarningPlans((prevState) => {
      // Make a copy of the array to avoid mutating state directly
      const updatedEarningPlans = [...prevState];
      // Update the amount property of the object at the specified index
      updatedEarningPlans[index] = {
        ...updatedEarningPlans[index],
        amount: amount,
      };
      return updatedEarningPlans;
    });
  };

  const handleDeletePlan = () => {
    setEarningPlans((prevState) => {
      // Filter out the item with the specified index
      const updatedEarningPlans = prevState.filter((_, idx) => idx !== index);
      return updatedEarningPlans;
    });
    // }
  };

  return (
    <EarningPlanStyled $expired={expired} $active={active}>
      <Div>
        <P $bold $mb="4">
          {renderPlanName()}
        </P>
        <Disclaimer $mb="4" color={!active && lightTheme.gray}>
          {amount}
        </Disclaimer>
      </Div>

      <Div $flex $alignCenter $gap="16px">
        {isLifetime && (
          <Disclaimer $m="0" $right $color={lightTheme.lightGray}>
            Plan that starts when
            <br />
            agent’s Year Plan Ends
          </Disclaimer>
        )}
        <EarningPlanStatus active={active} expired={expired} />
        {!expired && (
          <Icon $pointer onClick={() => setEditModal(true)}>
            <EditPenIconDarkGray />
          </Icon>
        )}
      </Div>
      <EditEarningPlan
        planAmount={plan.amount}
        modalIsOpen={editModal}
        setModalIsOpen={setEditModal}
        label={renderPlanName()}
        handleSetAmount={handleSetAmount}
        handleDeletePlan={handleDeletePlan}
      />
    </EarningPlanStyled>
  );
};

export const EarningPlanVOrdersVDrive = ({ planName, amount, isEnabled }) => {
  return (
    <EarningPlanStyled $expired={!isEnabled} $active={isEnabled}>
      <Div>
        <TextWithTooltipIcon
          bold
          text={planName}
          tooltip={
            !isEnabled &&
            'This service is not enabled for this location in order to define VR plans.'
          }
        />

        <Disclaimer $mb="4" color={!isEnabled && lightTheme.gray}>
          {formatter.format(centsToDollars(amount))}
        </Disclaimer>
      </Div>

      <EarningPlanVOrdersVDriveStatus isEnabled={isEnabled} />
    </EarningPlanStyled>
  );
};

const EarningPlanVOrdersVDriveStatus = ({ isEnabled }) => {
  return (
    <Pill $expired={!isEnabled}>
      <Disclaimer $m="0" $color={lightTheme.white}>
        {isEnabled ? 'Active' : 'Inactive'}
      </Disclaimer>
    </Pill>
  );
};
