import { cssTransition, toast } from 'react-toastify';
import { ToastError, ToastSuccess } from '@vgrubs/components';
import { IconMessageWrap, IconWrap } from './ToastsStyled';

const move = cssTransition({
  enter: 'move-in',
  exit: 'move-out',
});

const toastConfig = {
  icon: false,
  hideProgressBar: true,
  pauseOnHover: true,
  autoClose: 5000,
  position: 'top-center',
  draggable: true,
  closeOnClick: true,
  // closeButton: <X fill={lightTheme.darkGray + lightTheme.transparency.t50} />,
  transition: move,
};

export const SuccessToast = (successMessage) => {
  toast.success(
    <SuccessToastComponent successMessage={successMessage ?? 'Success'} />,
    toastConfig
  );
};

export const ErrorToast = (errorMessage) => {
  if (!errorMessage) return;
  toast.error(
    <ErrorToastComponent errorMessage={errorMessage ?? 'Error'} />,
    toastConfig
  );
};

export const SuccessToastComponent = ({ successMessage }) => (
  <IconMessageWrap>
    <IconWrap>
      <ToastSuccess />
    </IconWrap>
    <div>
      <h5>Success</h5>
      <p>{successMessage}</p>
    </div>
  </IconMessageWrap>
);

export const ErrorToastComponent = ({ errorMessage }) => (
  <IconMessageWrap>
    <IconWrap>
      <ToastError />
    </IconWrap>
    <div>
      <h5>Error</h5>
      <p>{errorMessage}</p>
    </div>
  </IconMessageWrap>
);
