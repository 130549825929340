import {
  ADMIN_TRANSACTIONS,
  AdminDatePicker,
  AdminHeader,
  AdminSpinner,
  Div,
  ErrorToast,
  H3,
  H5,
  HeaderIconWrapper,
  Img,
  P,
  api,
  handleSetUrlSearchParams,
  transactionsNoData,
} from '@vgrubs/components';
import { DateTime } from 'luxon';
import { useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { useLocation, useMatch, useSearchParams } from 'react-router-dom';
import { Tab } from '../Verifications/VerificationsStyled';
import { TransactionsVGrubs } from './TransactionsVGrubs';
import { TransactionsVDrive } from './TransactionsVDrive';
import { showNoDataText } from './TransactonsStyled';
import { OrderDetails } from '../Orders/OrderDetails';

const setTimeStartEnd = (date, type) => {
  if (type === 'start') {
    return DateTime.fromJSDate(date).startOf('day').toUTC().toISO();
  }
  return DateTime.fromJSDate(date).endOf('day').toUTC().toISO();
};

const dateIsToday = (dateToCheck) => {
  const date = DateTime.fromISO(dateToCheck).toISODate();
  const today = DateTime.local().toISODate();

  return date === today;
};

const groupTransactionsByDate = (transactions) => {
  // Create a Map to group transactions by date
  const groupedMap = new Map();

  transactions.forEach((item) => {
    // Extract only the date part (YYYY-MM-DD) from createdAt
    // const date = item.createdAt.split('T')[0];
    const date = DateTime.fromISO(item.createdAt)
      // .setZone(order.timeZone)
      .toISODate();

    // If the date is not already in the Map, initialize it with an empty array
    if (!groupedMap.has(date)) {
      groupedMap.set(date, []);
    }

    // Push the current item into the array for this date
    groupedMap.get(date).push(item);
  });

  // Convert the Map into an array of objects with { date, items }
  return Array.from(groupedMap, ([date, items]) => ({ date, items }));
};

export const Transactions = () => {
  const [loading, setLoading] = useState(false);
  const [transactions, setTransactions] = useState([]);
  const [transactionsByDate, setTransactionsByDate] = useState([]);
  const [sumTransactions, setSumTransactions] = useState({
    vGrubsIncome: 0,
    vGrubsWithdrawals: 0,
    vDriveDeposits: 0,
    vDriveTransactions: 0,
    lastVGrubsIncome: 0,
    lastVGrubsWithdrawal: 0,
    lastVDriveDeposit: 0,
    vDriveDepositsReports: 0,
  });

  const limit = 20;
  const [hasMore, setHasMore] = useState(true);
  const [ref, inView] = useInView();
  const match = useMatch(ADMIN_TRANSACTIONS);
  const userId = match.params.id;

  // console.log(location);

  async function getTransactions(isWithScroll, hasMore, page, isFilter) {
    if (!hasMore) return;

    const queryString = new URLSearchParams({
      from,
      to,
      page,
      limit,
      type: vType + type,
    }).toString();

    const queryStringNoPag = new URLSearchParams({
      from,
      to,
    }).toString();

    try {
      setLoading(true);
      const { data } = await api.get(
        `/v1/wallet/${userId}/new-transactions?${queryString}`
      );

      const res = await api.get(
        `/v1/wallet/${userId}/sum-transactions?${queryStringNoPag}`
      );

      const {
        vGrubsIncome,
        vGrubsWithdrawals,
        vDriveDeposits,
        vDriveTransactions,
        lastVGrubsIncome,
        lastVGrubsWithdrawal,
        lastVDriveDeposit,
        vDriveDepositsReports,
      } = res.data.data;

      setSumTransactions({
        vGrubsIncome: vGrubsIncome || 0,
        vGrubsWithdrawals: vGrubsWithdrawals || 0,
        vDriveDeposits: vDriveDeposits || 0,
        vDriveTransactions: vDriveTransactions || 0,
        lastVGrubsIncome: lastVGrubsIncome || 0,
        lastVGrubsWithdrawal: lastVGrubsWithdrawal || 0,
        lastVDriveDeposit: lastVDriveDeposit || 0,
        vDriveDepositsReports: vDriveDepositsReports || 0,
      });

      let fetchedTransactions = data.data;

      if (fetchedTransactions.length === limit) {
        setPage(Number(page) + 1);
        setHasMore(true);
      } else {
        setHasMore(false);
      }

      // Combine and group the transactions as an array by date
      let combinedTransactions = isFilter
        ? fetchedTransactions
        : [...transactions, ...fetchedTransactions];

      const grouped = groupTransactionsByDate(combinedTransactions);
      setTransactions(combinedTransactions);
      setTransactionsByDate(grouped);
      setLoading(false);
    } catch (error) {
      ErrorToast(error?.response?.data?.message);
      setLoading(false);
    }
  }

  const today = new Date();

  const INITIAL_DATE = [
    setTimeStartEnd(today, 'start'),
    setTimeStartEnd(today, 'end'),
  ];

  const [dateInput, setDateInput] = useState([today, today]);

  const [searchParams, setSearchParams] = useSearchParams();

  const from = searchParams.get('from') ?? INITIAL_DATE[0];
  const to = searchParams.get('to') ?? INITIAL_DATE[1];
  const page = searchParams.get('page') ?? 1;
  const type = searchParams.get('type') ?? 'Incomes';
  const vType = searchParams.get('vType') ?? 'vGrubs';

  const handleDateChange = (date) => {
    setDateInput(date);
  };

  const handleDateApply = () => {
    setTransactions([]);

    if (dateInput.length < 2) {
      setDate([
        setTimeStartEnd(dateInput[0], 'start'),
        setTimeStartEnd(dateInput[0], 'end'),
      ]);
      setDateInput([dateInput[0], dateInput[0]]);
    } else {
      setDate([
        setTimeStartEnd(dateInput[0], 'start'),
        setTimeStartEnd(dateInput[1], 'end'),
      ]);
    }
  };

  const renderDateLabel = () => {
    const dateFrom = DateTime.fromISO(from).toFormat('MMM dd, yyyy');
    const dateTo = DateTime.fromISO(to).toFormat('MMM dd, yyyy');

    if (dateIsToday(from) && dateIsToday(to)) {
      return 'Today';
    } else return `${dateFrom} - ${dateTo}`;
  };

  const setDate = (date) => {
    handleSetUrlSearchParams('from', date[0], setSearchParams);
    handleSetUrlSearchParams('to', date[1], setSearchParams);
  };

  const setPage = (page) => {
    handleSetUrlSearchParams('page', page, setSearchParams, true);
  };

  const setType = (type, replace = false) => {
    handleSetUrlSearchParams('type', type, setSearchParams, replace);
  };

  const setVType = (vType) => {
    handleSetUrlSearchParams('vType', vType, setSearchParams);
  };

  useEffect(() => {
    if (userId) {
      setPage(1);
      setTransactions([]);
      setHasMore(true);
      getTransactions(false, true, 1, true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [from, to, type]);

  useEffect(() => {
    if (inView && transactions.length > 0) {
      getTransactions(true, hasMore, page, false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inView]);

  useEffect(() => {
    if (vType === 'vGrubs') {
      setType('Incomes', true);
    }
    if (vType === 'vDrive') {
      setType('Deposits', true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vType]);

  return (
    <div>
      {loading && <AdminSpinner />}
      <AdminHeader title="Transactions">
        <HeaderIconWrapper>
          <AdminDatePicker
            mode="range"
            value={dateInput}
            onChange={handleDateChange}
            click={handleDateApply}
          />
        </HeaderIconWrapper>
      </AdminHeader>
      <Div $flex $between $alignCenter $gap="20px" $gapSM="16px" $m="40px 0">
        <H3 $m="0">
          <span>
            {/* {location.state.firstName} {location.state.lastName} */}
          </span>
        </H3>
        <Div $flex $gap="20px" $gapSM="16px">
          <Tab selected={vType === 'vGrubs'} onClick={() => setVType('vGrubs')}>
            vGrubs
          </Tab>
          <Tab selected={vType === 'vDrive'} onClick={() => setVType('vDrive')}>
            vDrive
          </Tab>
        </Div>
      </Div>{' '}
      <H5 $mb="32">{renderDateLabel()}</H5>
      {vType === 'vGrubs' && (
        <TransactionsVGrubs
          transactions={transactionsByDate}
          sumTransactions={sumTransactions}
          setType={setType}
          type={type}
        />
      )}
      {vType === 'vDrive' && (
        <TransactionsVDrive
          transactions={transactionsByDate}
          sumTransactions={sumTransactions}
          setType={setType}
          type={type}
        />
      )}
      {transactions.length < 1 && !loading && (
        <Div $flex $column $alignCenter $mt="150">
          <Img $width="180px" src={transactionsNoData} />
          <H5 $mt="40" $center>
            {showNoDataText(type)}
          </H5>
        </Div>
      )}
      <div ref={ref}></div>
      <OrderDetails />
    </div>
  );
};
