import styled from 'styled-components';
import {
  Disclaimer,
  Div,
  Icon,
  InfoIcon,
  P,
  SvgInlineWrapper,
  lightTheme,
} from '@vgrubs/components';
import { Tooltip } from 'react-tooltip';
import { InputSearchIcon } from '@vgrubs/components';

const AdminInputStyled = styled.input`
  width: 100%;
  border: 1px solid ${({ theme }) => theme.darkGray}80;
  border-radius: 200px;
  font-size: 15px;
  line-height: 18px;
  padding: 0 24px;
  display: flex;
  align-items: center;
  height: 45px;
  -moz-appearance: none; /* Firefox */
  -webkit-appearance: none; /* Safari and Chrome */
  appearance: none;
  background: none;

  :disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }

  ${({ shadowDesign, theme }) =>
    shadowDesign &&
    `
  
  border: 1px solid #e3e3e3;
  box-shadow: ${theme.shadows.shadow1};

  `}

  ${({ search }) => search && `padding-right: 50px`};
  ${({ $textCenter }) => $textCenter && `text-align: center`};

  :focus {
    outline: none !important;
    border: 1px solid ${({ theme }) => theme.primary};
  }

  ${({ $password }) =>
    $password &&
    `

  text-security: disc;
  -webkit-text-security: disc;
  -mox-text-security: disc;
  
  `};

  ::-webkit-inner-spin-button,
  ::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
  }

  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
`;

const AdminTextAreaStyled = styled.textarea`
  width: 100%;
  border: 1px solid ${({ theme }) => theme.darkGray}80;
  border-radius: 20px;
  font-size: 15px;
  line-height: 18px;
  padding: 20px;
  display: flex;
  min-width: 100%;
  max-width: 100%;
  min-height: 300px;
  max-height: 300px;
  /* padding: 16px; */
  /* align-items: center; */
  height: 300px;
  -moz-appearance: none; /* Firefox */
  -webkit-appearance: none; /* Safari and Chrome */
  appearance: none;
  background: none;

  ${({ shadowDesign, theme }) =>
    shadowDesign &&
    `
  
  border: 1px solid #e3e3e3;
  box-shadow: ${theme.shadows.shadow1};

  `}

  ${({ $textCenter }) => $textCenter && `text-align: center`};

  :focus {
    outline: none !important;
    border: 1px solid ${({ theme }) => theme.primary};
  }

  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
`;

export const AdminInput = (props) => {
  return (
    <Div
      $relative
      $width="100%"
      $maxWidth={props?.maxWidth}
      $maxWidthSM={props?.maxWidthSM}
      $mb={props?.mb}
    >
      {props.label && (
        <P
          $mb="8"
          $ml="24"
          $mr="24"
          $bold
          $center={props.$textCenter}
          $color={lightTheme.darkGray}
        >
          {props.label}{' '}
          {props.tooltip && <InputLabelTooltip tooltip={props.tooltip} />}
        </P>
      )}
      {props.type === 'textarea' ? (
        <AdminTextAreaStyled {...props} />
      ) : (
        <AdminInputStyled {...props} />
      )}
      {props.maxLength && !props.noMaxLengthLabel && (
        <Disclaimer
          style={{ position: 'absolute', right: 12, bottom: -16 }}
          $m="0"
          $mt="4"
          $right
        >
          {props.value?.length}/{props.maxLength}
        </Disclaimer>
      )}

      {props.search && (
        <Icon style={{ position: 'absolute', right: 24, top: 12 }}>
          <InputSearchIcon />
        </Icon>
      )}

      {props.tooltip && (
        <>
          <Div $desktop>
            <Tooltip
              clickable
              id={props.tooltip}
              style={{
                maxWidth: '500px',
                background: lightTheme.white,
                color: lightTheme.darkGray,
                padding: 16,
                borderRadius: 20,
                boxShadow: '0px 10px 15px rgba(0, 0, 0, 0.05)',
                zIndex: '1',
              }}
              border="1px solid #e3e3e3"
            />
          </Div>
          <Div $mobile>
            <Tooltip
              id={props.tooltip}
              style={{
                maxWidth: '95%',
                background: lightTheme.white,
                color: lightTheme.darkGray,
                padding: 16,
                borderRadius: 20,
                boxShadow: '0px 10px 15px rgba(0, 0, 0, 0.05)',
                zIndex: '1',
              }}
              border="1px solid #e3e3e3"
            />
          </Div>
        </>
      )}
    </Div>
  );
};

const InputLabelTooltip = ({ tooltip }) => (
  <a
    data-tooltip-id={tooltip}
    data-tooltip-html={tooltip}
    data-tooltip-place="top"
    style={{ color: lightTheme.darkGray }}
  >
    <SvgInlineWrapper>
      <InfoIcon />
    </SvgInlineWrapper>
  </a>
);
