import styled from 'styled-components';

export const EarningPlanStyled = styled.div`
  padding: 10px 24px;
  border: 1px solid
    ${({ $expired, $active, theme }) =>
      $expired ? theme.lightGray : $active ? theme.primary : 'none'};
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 20px;
  margin-bottom: 16px;
  background-color: ${({ $expired, theme }) =>
    $expired ? 'none' : theme.white};

  box-shadow: ${({ $expired, theme }) =>
    $expired ? 'none' : theme.shadows.shadow1};
`;

export const Pill = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 28px;
  width: 70px;
  background-color: ${({ $expired, theme }) =>
    $expired ? theme.lightGray : theme.primary};
  border-radius: 20px;
`;
