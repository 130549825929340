import {
  Disclaimer,
  Div,
  EditPenDarkBlueIcon,
  H2,
  Icon,
  IconButtonStyled,
  P,
  Separator,
  VPayLogoBlue,
  centsToDollars,
  formatter,
  lightTheme,
  useUsers,
} from '@vgrubs/components';
import { UserPendingEarningsCreditCardStyled } from '../UsersAndAccessStyled';
import { DateTime } from 'luxon';

export const UserAgentPendingEarningsCreditCard = ({ pendingBalance }) => {
  const { setEditPendingBalanceModal } = useUsers((state) => state);

  const handleEditBalanceModal = () => {
    setEditPendingBalanceModal(true);
  };

  const getPreviousMonthFormatted = () => {
    const today = DateTime.local();

    // Get first and last day of last month
    const firstDay = today.minus({ months: 1 }).startOf('month');
    const lastDay = today.minus({ months: 1 }).endOf('month');

    // Format as "1 January - 31 January"
    return `${firstDay.toFormat('d LLLL')} - ${lastDay.toFormat('d LLLL')}`;
  };

  // Usage
  const previousMonthRange = getPreviousMonthFormatted();

  return (
    <>
      <UserPendingEarningsCreditCardStyled>
        <Div $flex $alignCenter $between $gap="50px" $gapSM="24px">
          <VPayLogoBlue height="100px" width="100px" />
          <Div>
            <P $right $mb="0" $color={lightTheme.blue}>
              Pending Earnings
            </P>
            <P $right $mb="4" $color={lightTheme.lightGray}>
              ({previousMonthRange})
            </P>
            <H2 $right $m="0" $color={lightTheme.blue}>
              {formatter.format(
                pendingBalance ? centsToDollars(pendingBalance) : 0
              )}
            </H2>
          </Div>
        </Div>
        <Separator $m="24px 0" />
        <Div $flex $alignCenter $between $gap="24px" $gapSM="16px">
          <Div
            $flex
            $alignCenter
            $gap="12px"
            $gapSM="8px"
            onClick={handleEditBalanceModal}
          >
            <IconButtonStyled $color={lightTheme.blue}>
              <Icon $size="24" color={lightTheme.white}>
                <EditPenDarkBlueIcon />
              </Icon>
            </IconButtonStyled>
            <Disclaimer $m="0" $color={lightTheme.blue}>
              Edit Pending <br /> Earnings
            </Disclaimer>
          </Div>
        </Div>
      </UserPendingEarningsCreditCardStyled>
    </>
  );
};
