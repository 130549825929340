import {
  Button,
  Disclaimer,
  Div,
  InfoIcon,
  LocationPinSmallIcon,
  P,
  StopIcon,
  SvgInlineWrapper,
  VDriveWhiteIcon,
  lightTheme,
} from '@vgrubs/components';
import { Tooltip } from 'react-tooltip';

export const vDriveStatus = {
  ASSIGNED: 'assigned',
  CANCELED: 'cancelled',
  CAN_BE_CANCELED: 'canBeCancelled',
  NOT_ASSIGNED: 'notAssigned',
  COMPLETED: 'completed',
};

const chooseVDriveStatusText = (v, cancelledBy) => {
  switch (v) {
    case vDriveStatus.ASSIGNED:
      return 'Delivery in progress.';
    case vDriveStatus.CANCELED:
      return cancelledBy === 'api'
        ? 'Delivery has failed!'
        : 'Delivery cancelled by Merchant.';
    case vDriveStatus.CAN_BE_CANCELED:
      return 'Assigning vDriver.';
    case vDriveStatus.NOT_ASSIGNED:
      return 'No Driver Assigned.';
    case vDriveStatus.COMPLETED:
      return 'Successful Delivery!';

    default:
      break;
  }
};

const chooseVDriveStatusButton = (v) => {
  switch (v) {
    case vDriveStatus.ASSIGNED:
      return 'Track vDriver';
    case vDriveStatus.CANCELED:
      return 'Assign vDriver';
    case vDriveStatus.CAN_BE_CANCELED:
      return 'Stop vDriver';
    case vDriveStatus.NOT_ASSIGNED:
      return 'Assign vDriver';
    case vDriveStatus.COMPLETED:
      return 'See vDrive Delivery';

    default:
      break;
  }
};

const chooseVDriveStatusColor = (v) => {
  switch (v) {
    case vDriveStatus.ASSIGNED:
      return lightTheme.transparentBlue;
    case vDriveStatus.CANCELED:
      return lightTheme.transparentRed;
    case vDriveStatus.CAN_BE_CANCELED:
      return lightTheme.transparentBlue;
    case vDriveStatus.NOT_ASSIGNED:
      return lightTheme.transparentBlue;
    case vDriveStatus.COMPLETED:
      return lightTheme.transparentGreen;

    default:
      break;
  }
};

const chooseVDriveStatusColorBorder = (v) => {
  switch (v) {
    case vDriveStatus.ASSIGNED:
      return lightTheme.darkBlue;
    case vDriveStatus.CANCELED:
      return lightTheme.primary;
    case vDriveStatus.CAN_BE_CANCELED:
      return lightTheme.darkBlue;
    case vDriveStatus.NOT_ASSIGNED:
      return lightTheme.darkBlue;
    case vDriveStatus.COMPLETED:
      return lightTheme.green;

    default:
      break;
  }
};

export const VDriveStatusComponent = ({
  data,
  setCancelVDriveModal,
  getDrivers,
  setChangeAddressModal,
}) => {
  const openTrackingLink = () =>
    window.open(data.vDrive.trackingLink, '_blank', 'noopener,noreferrer');

  const chooseVDriveButtonFunction = (v) => {
    switch (v) {
      case vDriveStatus.ASSIGNED:
        return openTrackingLink();
      case vDriveStatus.CANCELED:
        return getDrivers();
      case vDriveStatus.CAN_BE_CANCELED:
        return setCancelVDriveModal(true);
      case vDriveStatus.NOT_ASSIGNED:
        return getDrivers();
      case vDriveStatus.COMPLETED:
        return openTrackingLink();

      default:
        break;
    }
  };

  return (
    <Div
      $m="0 0 20px 48px"
      $mSM="0 0 20px 0"
      $p="16px"
      $box
      $flex
      $alignCenter
      $gap="16px"
      $gapSM="8px"
      $between
      // $columnSM
      $background={chooseVDriveStatusColor(data?.vDrive?.status)}
      $borderColor={chooseVDriveStatusColorBorder(data?.vDrive?.status)}
    >
      <Div>
        <Div $flex $alignCenter>
          <P $m="0">
            {chooseVDriveStatusText(
              data?.vDrive?.status,
              data?.vDrive?.cancelledBy
            )}{' '}
          </P>
          {data?.vDrive?.cancellationReason && (
            <TooltipIcon
              tooltip={`Reason: ${data?.vDrive?.cancellationReason}`}
            />
          )}
        </Div>

        {data?.vDrive?.status === vDriveStatus.NOT_ASSIGNED && (
          <Div
            $flex
            $alignCenter
            $gap="4px"
            onClick={() => setChangeAddressModal(data.id)}
            $pointer
            $mt="4"
          >
            <LocationPinSmallIcon />
            <Disclaimer $underline $m="0">
              Wrong Address?
            </Disclaimer>
          </Div>
        )}
        {data?.vDrive?.status === vDriveStatus.ASSIGNED && (
          <Div
            $flex
            $alignCenter
            $gap="4px"
            onClick={() => setCancelVDriveModal(true)}
            $pointer
            $mt="4"
          >
            <StopIcon />
            <Disclaimer $underline $m="0">
              Stop vDriver
            </Disclaimer>
          </Div>
        )}
      </Div>
      <Button
        style={{ flex: 'none' }}
        $w="auto"
        $wSM="auto"
        $p
        $pSM
        $medium
        $darkBlue={data?.vDrive.status !== vDriveStatus.CAN_BE_CANCELED}
        $selected
        onClick={() => chooseVDriveButtonFunction(data?.vDrive?.status)}
      >
        <Div $flex $alignCenter $gap="8px">
          <VDriveWhiteIcon /> {chooseVDriveStatusButton(data?.vDrive?.status)}
        </Div>
      </Button>
    </Div>
  );
};

export const TooltipIcon = ({ tooltip }) => {
  const random = Math.random();
  return (
    <>
      <a
        data-tooltip-id={random}
        data-tooltip-html={tooltip}
        data-tooltip-place="top"
        style={{ color: lightTheme.darkGray }}
      >
        <SvgInlineWrapper style={{ color: lightTheme.darkGray }}>
          <InfoIcon />
        </SvgInlineWrapper>
      </a>
      <Div $desktop>
        <Tooltip
          clickable
          id={random}
          style={{
            maxWidth: '500px',
            background: lightTheme.white,
            color: lightTheme.darkGray,
            padding: 16,
            borderRadius: 20,
            boxShadow: '0px 10px 15px rgba(0, 0, 0, 0.05)',
            zIndex: '100',
          }}
          border="1px solid #e3e3e3"
        />
      </Div>
      <Div $mobile>
        <Tooltip
          id={random}
          style={{
            maxWidth: '95%',
            background: lightTheme.white,
            color: lightTheme.darkGray,
            padding: 16,
            borderRadius: 20,
            boxShadow: '0px 10px 15px rgba(0, 0, 0, 0.05)',
            zIndex: '100',
          }}
          border="1px solid #e3e3e3"
        />
      </Div>
    </>
  );
};
