import {
  ADMIN_VERIFICATIONS_AGENT,
  AdminHeader,
  AdminSpinner,
  AnimatedDiv,
  Button,
  Div,
  DownloadIcon,
  ErrorToast,
  H2,
  H3,
  H5,
  Icon,
  ImagePreview,
  Separator,
  SuccessToast,
  api,
  downloadImage,
  useUsers,
  useVerifications,
} from '@vgrubs/components';
import { useEffect, useState } from 'react';
import { useMatch, useNavigate } from 'react-router';
import { SubTitleWithTooltipIcon } from '../Transactions/TransactonsStyled';
import { KeyValueInfo } from './components/KeyValueInfo';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import { RejectModalAgent } from './RejectModalAgent';

export const AgentDetails = () => {
  const [loading, setLoading] = useState('');
  const [agentDetails, setAgentDetails] = useState(null);
  const navigate = useNavigate();
  const [rejectModal, setRejectModal] = useState(false);

  const { loading: balanceLoading } = useUsers((state) => state);

  const { getCount } = useVerifications((state) => state);

  const id = useMatch(ADMIN_VERIFICATIONS_AGENT).params.id;

  const agent = agentDetails?.agent;
  const user = agentDetails?.user;

  async function handleGetAgentDetails() {
    try {
      setLoading(true);
      const { data } = await api.get(`/v1/agents`, {
        params: { userId: id },
      });
      setAgentDetails(data.data);
    } catch (error) {
      ErrorToast(error?.response?.data?.message);
    } finally {
      setLoading(false);
    }
  }

  async function handleVerifyAgent() {
    try {
      setLoading(true);
      const { data } = await api.post(`/v1/agents/${agent.id}`, body);
      SuccessToast(data.message);
      getCount();
      navigate(-1);
    } catch (error) {
      ErrorToast(error?.response?.data?.message);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    if (id) {
      handleGetAgentDetails();
    }
  }, []);

  return (
    <>
      <AdminHeader />

      {(loading || balanceLoading) && <AdminSpinner />}
      {agentDetails && (
        <AnimatedDiv>
          <Div $flex $between $columnSM $alignCenter>
            <H2 $m="40px 0">
              <span>{user.firstName + ' ' + user.lastName}</span>
            </H2>
            <Div $flex $column $gap="16px">
              <Button
                $selected
                $w="300px"
                $wSM="100%"
                onClick={handleVerifyAgent}
              >
                Verify Agent
              </Button>

              <Button
                $selected
                $gray
                $w="300px"
                $wSM="100%"
                onClick={() => setRejectModal(agent.id)}
              >
                Reject Agent
              </Button>
            </Div>
          </Div>

          <AnimatedDiv $flex $columnSM $gap="50px" $mt="40" $mtSM="20">
            <Div $width="50%" $widthSM="100%">
              <Separator />
              <a href={`mailto:${user.mail}`}>
                <KeyValueInfo k="Email" v={user.email} />
              </a>
              <a href={`tel:${user.phoneNumber}`}>
                <KeyValueInfo k="Phone" v={user.phoneNumber} />
              </a>
            </Div>
            <Div $width="50%" $widthSM="100%">
              <H3>Account Verification</H3>

              <Div $flex $columnSM $gap="20px">
                <Div $width="50%" $widthSM="100%">
                  <SubTitleWithTooltipIcon
                    text={'ID Card'}
                    mb="16px"
                    tooltip={'Need Text'}
                  />
                  <Div $box $p="16px" $pSM="16px" $mb="50" $mbSM="24">
                    <ImagePreview
                      image={agent.idCardFrontUrl}
                      borderRadius="10px"
                    />
                  </Div>
                </Div>
                <Div $width="50%" $widthSM="100%">
                  <SubTitleWithTooltipIcon
                    text={'Selfie'}
                    mb="16px"
                    tooltip={'Need Text'}
                  />
                  <Div $box $p="16px" $pSM="16px" $mb="50" $mbSM="24">
                    <ImagePreview
                      image={agent.idCardFrontUrl}
                      borderRadius="10px"
                    />
                  </Div>
                </Div>
              </Div>

              {/* W9 FORM */}
              <Div $box $p="20px 24px" $flex $between $alignCenter>
                <H5 $m="0">W9 Form</H5>

                <Icon
                  $pointer
                  onClick={() =>
                    downloadImage(
                      agent.w9FormUrl,
                      `Agent ${user.firstName + ' ' + user.lastName} W9 Form`
                    )
                  }
                >
                  <DownloadIcon />
                </Icon>
              </Div>
              {/* W9 FORM */}
            </Div>
          </AnimatedDiv>
          <Div $flex $justifyEnd $mt="50"></Div>
        </AnimatedDiv>
      )}
      <RejectModalAgent
        modalIsOpen={rejectModal}
        setModalIsOpen={setRejectModal}
        setLoading={setLoading}
      />
    </>
  );
};
