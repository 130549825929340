import {
  Button,
  Div,
  Modal,
  P,
  Radio,
  displayCentsInDollarsShort,
} from '@vgrubs/components';

export const EditServiceFee = ({
  vOrdersMonthlySalesFee,
  modalIsOpen,
  setModalIsOpen,
  vDriveService,
  preferences,
  handleChangeServiceFee,
}) => {
  const monthlyServiceFees = vDriveService
    ? preferences.vDriveOnMonthlyFees
    : preferences.vDriveOffMonthlyFees ?? [];

  return (
    <Modal
      isOpen={modalIsOpen}
      setIsOpen={setModalIsOpen}
      header="Choose Service Fee"
      logo={false}
      zIndex={110}
      subtitle={`When vDrive is ${vDriveService ? 'On' : 'Off'}`}
      textAlign="left"
    >
      {monthlyServiceFees?.map((fee, i) => (
        <ServiceFeeComponent
          monthlyFee={fee}
          key={i}
          selected={vOrdersMonthlySalesFee}
          handleChangeServiceFee={handleChangeServiceFee}
        />
      ))}
      <Button
        $selected
        $w="100%"
        $mt="32"
        onClick={() => setModalIsOpen(false)}
      >
        Confirm
      </Button>
    </Modal>
  );
};

const ServiceFeeComponent = ({
  monthlyFee,
  selected,
  handleChangeServiceFee,
}) => {
  const { fee, agentCommission } = monthlyFee;

  return (
    <Div
      $box
      $p="14px 24px"
      $mb="8"
      $flex
      $alignCenter
      $between
      onClick={() => handleChangeServiceFee(monthlyFee)}
      $gap="16px"
      $pointer
    >
      <div>
        <P $left $bold $mb="4" $lineHeight="1.2">
          Monthly Fee {displayCentsInDollarsShort(fee)}
        </P>
        <P $left $mb="0">
          <span>
            Sales Agent Commission {displayCentsInDollarsShort(agentCommission)}
          </span>
        </P>
      </div>

      <Radio checked={selected?.id === monthlyFee?.id} />
    </Div>
  );
};
