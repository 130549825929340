import {
  Button,
  Disclaimer,
  Div,
  ErrorToast,
  H2,
  Icon,
  IconButtonStyled,
  P,
  Separator,
  TrashIcon,
  VPayLogo,
  api,
  centsToDollars,
  formatter,
  lightTheme,
  useUsers,
} from '@vgrubs/components';
import { UserCreditCardStyled } from '../UsersAndAccessStyled';
import { useEffect, useState } from 'react';
import { UserCreditCardNotConnected } from './UserCreditCardNotConnected';

export async function getBankByUserId(id, setLoading, setBank) {
  try {
    setLoading(true);
    const { data } = await api.get(`/v1/users/bank/${id}`);

    setBank(data.data);

    setLoading(false);
  } catch (error) {
    ErrorToast(error?.response?.data?.message);
    setLoading(false);
  }
}

export const UserCreditCard = ({ id, locations, isAgent = false }) => {
  const {
    setEditBalanceModal,
    setDeleteBankModal,
    setLoading,
    setBank,
    bank,
    setAddBankModal,
  } = useUsers((state) => state);

  useEffect(() => {
    getBankByUserId(id, setLoading, setBank);
  }, [id]);

  const handleEditBalanceModal = () => {
    if (!isAgent && locations?.length < 1)
      return ErrorToast('User is not owner of any location.');
    setEditBalanceModal(true);
  };

  if (bank === null) return null;

  return (
    <UserCreditCardStyled>
      <Div $flex $alignCenter $between $gap="50px" $gapSM="24px">
        <VPayLogo height="100px" width="100px" />
        <Div>
          <P $right $mb="4" $color={lightTheme.white}>
            Virtual Order <br /> Available Balance
          </P>
          <H2 $right $m="0" $color={lightTheme.white}>
            {formatter.format(
              bank.balance.availableBalance
                ? centsToDollars(bank.balance.availableBalance)
                : 0
            )}
          </H2>
        </Div>
      </Div>
      <Separator $m="24px 0" $color={lightTheme.white} />
      <Div $flex $alignCenter $between $gap="24px" $gapSM="16px">
        {bank.isBankConnected && (
          <Div
            $flex
            $alignCenter
            $gap="12px"
            $gapSM="8px"
            onClick={() => setDeleteBankModal(true)}
          >
            <IconButtonStyled $color={lightTheme.primary}>
              <Icon $size="24">
                <TrashIcon />
              </Icon>
            </IconButtonStyled>
            <Disclaimer $m="0" $color={lightTheme.white}>
              Remove Bank <br /> Account{' '}
            </Disclaimer>
          </Div>
        )}

        <Button
          // $selected={bank.isBankConnected}
          $whiteBlue
          $w={bank.isBankConnected ? '150px' : '50%'}
          $wSM={bank.isBankConnected ? '110px' : '50%'}
          onClick={handleEditBalanceModal}
        >
          Edit Balance
        </Button>

        {!bank.isBankConnected && (
          <Button
            $selected
            $whiteBlue
            $w={bank.isBankConnected ? '150px' : '50%'}
            $wSM={bank.isBankConnected ? '110px' : '50%'}
            onClick={() => setAddBankModal(true)}
          >
            Connect Bank
          </Button>
        )}
      </Div>
    </UserCreditCardStyled>
  );
};
