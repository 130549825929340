import {
  AdminInput,
  Button,
  ErrorToast,
  Modal,
  SuccessToast,
  api,
} from '@vgrubs/components';
import { useState } from 'react';
import { useNavigate } from 'react-router';

export const RejectModalAgent = ({
  modalIsOpen,
  setModalIsOpen,
  setLoading,
}) => {
  const [reason, setReason] = useState('');
  const navigate = useNavigate();

  const id = modalIsOpen;

  async function handleRejectAgent() {
    const body = {
      reason,
    };

    try {
      setLoading(true);
      const { data } = await api.post(
        `/v1/agents/${id}/reject-verification`,
        body
      );
      SuccessToast(data.message);
      setModalIsOpen(false);
      navigate(-1);
    } catch (error) {
      ErrorToast(error?.response?.data?.message);
    } finally {
      setLoading(false);
    }
  }

  return (
    <Modal
      isOpen={modalIsOpen}
      setIsOpen={setModalIsOpen}
      logo={false}
      header="Reject Agent"
    >
      <AdminInput
        type="textarea"
        placeholder="Enter rejection reason"
        value={reason}
        onChange={(e) => setReason(e.target.value)}
      />

      <Button $selected $w="100%" $mt="40" onClick={handleRejectAgent}>
        Save
      </Button>
    </Modal>
  );
};
