import {
  Chevron,
  Div,
  EditPenIcon,
  H5,
  Icon,
  ImagePlaceholderCircle,
  ImagePlaceholderIcon,
  Img,
  P,
  TrashIconRed,
  centsToDollars,
  formatter,
  lightTheme,
} from '@vgrubs/components';
import { useEffect, useRef, useState } from 'react';
import { Collapsible, ItemListImage } from '../../MenyStyled';

export const MenuItemTypeComponent = ({
  item,
  setPausedModal,
  onClick,
  onClickDelete,
  menu,
}) => {
  const ref = useRef(null);
  const [height, setHeight] = useState(0);

  useEffect(() => {
    setHeight(ref?.current?.clientHeight);
  }, [menu]);

  const [expanded, setExpanded] = useState(false);

  return (
    <>
      <Div
        $box
        $p="16px 24px"
        $flex
        $alignCenter
        $between
        $mb="8"
        $background={expanded && lightTheme.primary}
        $pointer
        onClick={() => setExpanded((prev) => !prev)}
      >
        <Div $flex $alignCenter $gap="8px">
          <P $bold $m="0" $color={expanded && lightTheme.white}>
            {item.name} ({item.children.length})
          </P>
        </Div>
        <Icon
          $pointer
          $rotate={expanded ? '360deg' : '180deg'}
          color={expanded ? lightTheme.white : lightTheme.darkGray}
        >
          <Chevron />
        </Icon>
      </Div>

      <Collapsible expanded={expanded} elementHeight={height}>
        <div ref={ref}>
          {item.children.map((itemItem, i) => {
            return (
              <MenuItemComponent
                item={itemItem}
                category={item}
                key={i}
                setPausedModal={setPausedModal}
                onClick={onClick}
                onClickDelete={onClickDelete}
              />
            );
          })}
          {item.children.length === 0 ? (
            <P $m="0" $center>
              No items
            </P>
          ) : null}
        </div>
      </Collapsible>
    </>
  );
};

export const MenuItemComponent = ({
  item,
  // setPausedModal,
  onClick,
  onClickDelete,
  key,
  category,
}) => {
  return (
    <Div key={key} $box $p="12px 24px " $flex $alignCenter $between $mb="8">
      <Div
        $width="100%"
        $flex
        $alignCenter
        $gap="16px"
        $pointer
        onClick={() => onClick({ item, category })}
      >
        <Div $flex $none>
          {item.imageUrl ? (
            <ItemListImage>
              <Img
                height="50px"
                src={item.imageUrl ? item.imageUrl : ImagePlaceholderIcon}
              />
            </ItemListImage>
          ) : (
            <ImagePlaceholderCircle />
          )}
        </Div>

        <div>
          <P $mb="0">{item.name}</P>
          <H5 $m="0" $color={lightTheme.primary}>
            {formatter.format(centsToDollars(item.price))}
          </H5>
        </div>
      </Div>
      <Div $flex $alignCenter $between $gap="24px" $gapSM="12px">
        <Icon $shadow $pointer onClick={() => onClick({ item, category })}>
          <EditPenIcon />
        </Icon>
        <Icon $pointer onClick={() => onClickDelete({ item, category })}>
          <TrashIconRed />
        </Icon>
        {/* <Icon>
          {item.paused ? (
            <ResumeLogo />
          ) : (
            <Icon $pointer onClick={() => setPausedModal(true)}>
              <PauseLogo />
            </Icon>
          )}
        </Icon> */}
      </Div>
    </Div>
  );
};
