import {
  Div,
  ErrorToast,
  H5,
  P,
  Separator,
  SuccessToast,
  api,
  lightTheme,
} from '@vgrubs/components';
import { useState } from 'react';
import { AddEarningPlan } from './AddEarningPlan';
import { EarningPlans } from './EarningPlans';

export const Earnings = ({ referral, setLoading, id }) => {
  const [earningPlans, setEarningPlans] = useState([]);
  const [earning, setEarning] = useState(null);

  const [amountPerLocation, setAmountPerLocation] = useState(null);
  const [earningPlansModal, setEarningPlansModal] = useState(false);
  const [addEarningPlansModal, setAddEarningPlansModal] = useState(false);

  async function handleGetEarningPlans() {
    try {
      setLoading(true);
      const { data } = await api.get(`/v1/locations/${id}/earning-plan`);
      setEarningPlans(data.data.earningPlans);
      setEarning(data.data);
      setAmountPerLocation(data.data.amountPerLocation);
      setEarningPlansModal(true);
    } catch (error) {
      ErrorToast(error?.response?.data?.message);
    } finally {
      setLoading(false);
    }
  }

  async function handleEditEarningPlans() {
    const ep = earningPlans.map(({ id, year, amount }) => {
      return { id, year, amount };
    });

    const body = {
      earningPlans: ep,
    };

    try {
      setLoading(true);
      const { data } = await api.put(`/v1/locations/${id}/earning-plan`, body);
      SuccessToast(data.message);

      setEarningPlansModal(false);
    } catch (error) {
      ErrorToast(error?.response?.data?.message);
    } finally {
      setLoading(false);
    }
  }

  return (
    <>
      <Div $width="50%" $widthSM="100%">
        <Separator />
        <Div $p="16px 0" $gap="20px" $flex $alignCenter $between>
          <Div>
            <P $mb="4">Referral</P>
            <H5 $mb="0">
              {referral?.firstName} {referral?.lastName}
            </H5>
          </Div>

          {referral && (
            <P
              $m="0"
              $bold
              $underline
              $pointer
              $color={lightTheme.primary}
              onClick={handleGetEarningPlans}
            >
              <span>View Earning Plans</span>
            </P>
          )}
        </Div>
        <Separator />
      </Div>

      <EarningPlans
        modalIsOpen={earningPlansModal}
        setModalIsOpen={setEarningPlansModal}
        earningPlans={earningPlans}
        setEarningPlans={setEarningPlans}
        amountPerLocation={amountPerLocation}
        earning={earning}
        setAddEarningPlansModal={setAddEarningPlansModal}
        handleEditEarningPlans={handleEditEarningPlans}
      />

      <AddEarningPlan
        modalIsOpen={addEarningPlansModal}
        setModalIsOpen={setAddEarningPlansModal}
        earningPlans={earningPlans}
        amountPerLocation={amountPerLocation}
        setLoading={setLoading}
        setEarningPlans={setEarningPlans}
        setAmountPerLocation={setAmountPerLocation}
        earning={earning}
      />
    </>
  );
};
