import {
  AdminDollarInput2,
  Button,
  Div,
  ErrorToast,
  H3,
  Img,
  Modal,
  SuccessToast,
  api,
  moneyCoins,
  useUsers,
} from '@vgrubs/components';
import { useCallback, useEffect, useState } from 'react';

export const UserAgentEditPendingEarnings = ({
  id,
  pendingBalance,
  setAgentDetails,
}) => {
  const [amount, setAmount] = useState(0);
  const { editPendingBalanceModal, setEditPendingBalanceModal, setLoading } =
    useUsers((state) => state);

  async function handleAddBank() {
    if (amount === pendingBalance)
      return ErrorToast('Change Balance to proceed');

    try {
      setLoading(true);

      const body = {
        amount,
      };

      const { data } = await api.put(
        `/v1/agents/${id}/update-pending-balance`,
        body
      );
      SuccessToast(data.message);
      setAgentDetails(data.data);
      setEditPendingBalanceModal(false);
      setAmount(0);
    } catch (error) {
      ErrorToast(error?.response?.data?.message);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    if (pendingBalance) {
      setAmount(pendingBalance);
    }
  }, [pendingBalance]);

  const handleAmountChange = useCallback((val) => {
    setAmount(val);
  }, []);

  return (
    <Modal
      isOpen={editPendingBalanceModal}
      setIsOpen={setEditPendingBalanceModal}
      logo={false}
    >
      <Img width={230} src={moneyCoins} alt="edit balance" />
      <H3 $m="24px 0">Edit Pending Balance</H3>
      <Div $flex $column $gap="20px" $gapSM="16px">
        <AdminDollarInput2
          label="Pending Balance"
          onValueChange={handleAmountChange}
          value={amount}
          $textCenter
        />
      </Div>

      <Button $selected $w="100%" $mt="50" $mtSM="30" onClick={handleAddBank}>
        Save
      </Button>
      <Button $selected $gray $w="100%" $mt="16" onClick={handleAddBank}>
        Cancel
      </Button>
    </Modal>
  );
};
