import {
  ADMIN_USER_DETAILS_AGENT,
  AdminHeader,
  AdminSpinner,
  Button,
  Disclaimer,
  Div,
  EditPenIconRed,
  ErrorToast,
  H2,
  H3,
  H5,
  HeaderIconWrapper,
  LocationsPinRedIcon,
  Separator,
  UserTicketRedIcon,
  api,
  displayCentsInDollars,
  lightTheme,
  renderRole,
  useAuth,
  useUIState,
  useUsers,
} from '@vgrubs/components';
import { useEffect, useState } from 'react';
import { Link, useMatch, useNavigate } from 'react-router-dom';
import { Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import { KeyValueInfo } from '../../Verifications/components/KeyValueInfo';
import { UserAgentPendingEarningsCreditCard } from '..//Bank/UserAgentPendingEarningsCreditCard';
import { UserCreditCard } from '../Bank/UserCreditCard';
import { UserCreditCardAdd } from '../Bank/UserCreditCardAdd';
import { UserCreditCardDelete } from '../Bank/UserCreditCardDelete';
import { UserCreditCardEditBalance } from '../Bank/UserCreditCardEditBalance';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import { Pill } from '../../Locations/earnings/Earning.styled';
import { TooltipIcon } from '../../Orders/VDriveStatusComponent';
import { UserAgentEditPendingEarnings } from '../Bank/UserAgentEditPendingEarnings';
import { UsersAndAccessEditModal } from '../UsersAndAccessEdit';
import { UsersAndAccessDeleteModal } from '../UsersAndAccessDelete';

export const AgentEarningLevel = ({ level }) => {
  if (level) {
    return (
      <Div $flex $alignCenter $gap="6px">
        <Pill>
          <Disclaimer $m="0" $color={lightTheme.white}>
            Level {level.level}
          </Disclaimer>
        </Pill>
        <TooltipIcon
          tooltip={`Your current commission rate is ${displayCentsInDollars(
            level.price
          )}. To increase your commission, you need to unlock the next level. ${
            level.level < 4 &&
            `At present, you are ${
              level.nextLevelCount
            } locations away from reaching Level ${
              level.level + 1
            }, which offers a commission rate of ${displayCentsInDollars(
              level.nextLevelAmount
            )}.`
          }`}
        />
      </Div>
    );
  } else return null;
};

//

export const UserDetailsAgent = () => {
  const [loading, setLoading] = useState(false);
  const [agentDetails, setAgentDetails] = useState('');

  const agent = agentDetails?.agent;
  const user = agentDetails?.user;

  const { loading: loadingBank } = useUsers((state) => state);

  const [editModal, setEditModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);

  const id = useMatch(ADMIN_USER_DETAILS_AGENT).params.id;

  const { verificationsTab, setVerificationsTab } = useUIState(
    (state) => state
  );

  const navigate = useNavigate();

  async function handleGetAgentDetails() {
    try {
      setLoading(true);
      const { data } = await api.get(`/v1/agents`, {
        params: { userId: id },
      });
      setAgentDetails(data.data);
    } catch (error) {
      ErrorToast(error?.response?.data?.message);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    if (id) {
      handleGetAgentDetails();
    }
  }, []);

  const handleOnEdit = (user) => {
    setEditModal(user);
  };

  const handleOnDelete = (user) => {
    setDeleteModal(user);
  };

  const handleOnLocations = () => {
    setVerificationsTab(1);
    navigate('/verifications');
  };

  const handleOnAgent = () => {
    setVerificationsTab(2);
    navigate('/verifications');
  };

  return (
    <>
      {(loading || loadingBank) && <AdminSpinner />}

      {agentDetails && (
        <>
          <AdminHeader title={'Agent Details'}>
            <Div $flex $alignCenter $gap="42px">
              <AgentEarningLevel level={agent?.level} />
              <HeaderIconWrapper onClick={() => handleOnEdit(user)}>
                <EditPenIconRed />
              </HeaderIconWrapper>
            </Div>
          </AdminHeader>
          <Div $flex $between $columnSM $alignCenter $m="40px 0" $width="100%">
            <H2>
              <span>{user?.firstName + ' ' + user?.lastName}</span>
            </H2>
            <Div $flex $column $gap="16px" $width="300px" $widthSM="100%">
              <Link to={`/transactions/${user?.id}`} state={user}>
                <Button $selected $w="300px" $wSM="100%">
                  Transactions
                </Button>
              </Link>
              <Button
                $selected
                $gray
                $w="300px"
                $wSM="100%"
                onClick={() => handleOnDelete(user)}
              >
                Delete User
              </Button>
            </Div>
          </Div>
          <Div $flex $columnSM $gap="50px" $gapSM="20px">
            <Div $width="50%" $widthSM="100%">
              <Div $width="100%">
                <Separator />

                {user?.email && <KeyValueInfo k="Email" v={user?.email} />}

                {user?.phoneNumber && (
                  <KeyValueInfo k="Phone" v={user?.phoneNumber} />
                )}

                <KeyValueInfo k="Role" v={renderRole(user?.role)} />
              </Div>

              {/* AGENT BANK */}
              <Div $width="450px" $widthSM="400px">
                <Swiper
                  modules={[Pagination]}
                  spaceBetween={50}
                  slidesPerView={1}
                  onSwiper={(swiper) => console.log(swiper)}
                  pagination={{ clickable: true }}
                  style={{
                    '--swiper-pagination-color': lightTheme.blue,
                    '--swiper-pagination-bullet-inactive-color':
                      lightTheme.lightGray,
                    '--swiper-pagination-bullet-inactive-opacity': '1',
                    '--swiper-pagination-bullet-size': '10px',
                    '--swiper-pagination-bullet-horizontal-gap': '11px',
                  }}
                >
                  <SwiperSlide>
                    <Div $p="40px 0" $flex>
                      <UserCreditCard id={id} isAgent />
                    </Div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <Div $p="40px 0" $flex>
                      <UserAgentPendingEarningsCreditCard
                        pendingBalance={agent?.pendingBalance}
                      />
                    </Div>
                  </SwiperSlide>
                </Swiper>
              </Div>
              {/* AGENT BANK */}
            </Div>
            <Div $width="50%" $widthSM="100%">
              <H5 $mb="20">Earnings</H5>
              <Div $box $p="12px 20px" $mb="30">
                <H5 $mb="4">FLAT AMOUNT PER LOCATION</H5>
                <H5 $m="0">
                  <span>{displayCentsInDollars(agent?.amountPerLocation)}</span>
                </H5>
              </Div>

              <Div
                $box
                $flex
                $between
                $alignCenter
                $p="12px 20px"
                $mb="8"
                $pointer
                onClick={handleOnLocations}
              >
                <Div $flex $gap="16px" $alignCenter>
                  <LocationsPinRedIcon />
                  <div>
                    <H5 $mb="4">Signup Locations</H5>
                    <Disclaimer $m="0" $color={lightTheme.lightGray}>
                      Total invite: {agentDetails?.invitedLocationsCount ?? 0}
                    </Disclaimer>
                  </div>
                </Div>
                <H3 $m="0">
                  <span>{agentDetails?.signedLocationsCount ?? 0}</span>
                </H3>
              </Div>

              <Div
                $box
                $flex
                $between
                $alignCenter
                $p="12px 20px"
                $mb="8"
                $pointer
                onClick={handleOnAgent}
              >
                <Div $flex $gap="16px" $alignCenter>
                  <UserTicketRedIcon />
                  <div>
                    <H5 $mb="4">Agent List</H5>
                    <Disclaimer $m="0" $color={lightTheme.lightGray}>
                      Total invite: {agentDetails?.invitedAgentsCount ?? 0}
                    </Disclaimer>
                  </div>
                </Div>
                <H3 $m="0">
                  <span>{agentDetails?.verifiedAgentsCount ?? 0}</span>
                </H3>
              </Div>
            </Div>
          </Div>
        </>
      )}

      <UserCreditCardAdd id={user?.id} />
      <UserCreditCardDelete id={user?.id} />
      <UserCreditCardEditBalance id={user?.id} />

      <UserAgentEditPendingEarnings
        id={user?.id}
        pendingBalance={agent?.pendingBalance}
        setAgentDetails={setAgentDetails}
      />

      <UsersAndAccessEditModal
        modalIsOpen={editModal}
        setModalIsOpen={setEditModal}
        setLoading={setLoading}
        isAgent
        agentAmountPerLocation={agent?.amountPerLocation}
        setAgentDetails={setAgentDetails}
      />

      <UsersAndAccessDeleteModal
        setLoading={setLoading}
        setModalIsOpen={setDeleteModal}
        modalIsOpen={deleteModal}
        isAgent
      />
    </>
  );
};
