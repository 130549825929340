import styled from 'styled-components';

export const Tab = styled.div`
  width: 200px;
  background-color: ${({ selected, theme }) =>
    selected ? theme.white : 'none'};
  border: ${({ selected, theme }) =>
    selected ? `1px solid ${theme.white}` : `1px solid ${theme.lightGray}`};
  box-shadow: ${({ selected, theme }) =>
    selected ? theme.shadows.shadow1 : 'none'};
  color: ${({ selected, theme }) =>
    selected ? theme.primary : theme.darkGray};
  font-size: 15px;

  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 200px;
  cursor: pointer;
  transition: all 0.3s ease;
  flex: none;

  @media (max-width: 800px) {
    width: 120px;
  }
`;

export const TabsWrapper = styled.div`
  overflow-y: auto;
  display: flex;
  align-items: center;
  ::-webkit-scrollbar {
    display: none;
  }
  gap: 24px;
  padding: 32px 0;

  @media (max-width: 800px) {
    gap: 16px;
    margin: 0 -30px 0 -30px;
    padding: 32px 30px;
  }
`;

export const RestaurantStyled = styled.div`
  width: 100%;
  box-shadow: ${({ theme }) => theme.shadows.shadow1};
  background-color: ${({ theme }) => theme.white};
  padding: 12px 24px;
  border-radius: 15px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
  min-height: 70px;
  gap: 16px;

  :hover {
    h5 {
      color: ${({ theme }) => theme.primary};
      transition: all 0.3s ease;
    }
  }
`;

export const GridProviders = styled.div`
  display: flex;
  overflow-x: auto;
  padding: 10px 50px 40px 50px;
  gap: 24px;
  margin: 0 -50px 0 -50px;

  /* width: 100%; */

  @media (max-width: 800px) {
    display: flex;
    gap: 20px;
    overflow-x: auto;
    padding: 10px 30px 40px 30px;
    margin: 0 -30px 0 -30px;
  }

  ::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
`;
