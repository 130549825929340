'use client';

import {
  useEffect,
  useLayoutEffect,
  useState,
  useRef,
  useCallback,
} from 'react';

import { useLocation } from 'react-router';
import Payment from 'payment';
import {
  chowNowLogo,
  deliveryLogo,
  doordashLogo,
  eatstreetLogo,
  gloriaLogo,
  grubhubLogo,
  localForYouLogo,
  menufyLogo,
  uberLogo,
  chowNowIcon,
  deliveryIcon,
  doordashIcon,
  eatstreetIcon,
  gloriaIcon,
  grubhubIcon,
  localForYouIcon,
  menufyIcon,
  uberIcon,
  relayLogo,
  allyLogo,
  cutcatsLogo,
  dlivrdLogo,
  motoclickLogo,
} from '../assets';
import { DateTime } from 'luxon';

export const isDesktop = () => {
  const query = `(min-width: 800px)`;
  return window.matchMedia(query).matches;
};

export const isMobile = () => {
  const query = `(max-width: 800px)`;
  return window.matchMedia(query).matches;
};

export const isDesktopBig = () => {
  const query = `(min-width: 3500px)`;
  return window.matchMedia(query).matches;
};

export const ScrollToTop = ({ children }) => {
  const location = useLocation();
  useLayoutEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 200);
  }, [location.pathname]);
  return children;
};

export const useScrollToTop = () => {
  const scrollRef = useRef(null);

  const scrollToTop = useCallback(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }, []);

  return [scrollRef, scrollToTop];
};

export const useOnClickOutside = (ref, handler) => {
  useEffect(() => {
    const listener = (event) => {
      if (!ref.current || ref.current.contains(event.target)) {
        return;
      }
      handler(event);
    };
    document.addEventListener('mousedown', listener);
    document.addEventListener('touchstart', listener);
    return () => {
      document.removeEventListener('mousedown', listener);
      document.removeEventListener('touchstart', listener);
    };
  }, [ref, handler]);
};

export const t = 'true';

export const useWindowWide = (size) => {
  const [width, setWidth] = useState(0);
  useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth);
    }
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [setWidth]);

  return width > size;
};

export const Env = process.env;
export const facebookLink = Env.REACT_APP_FACEBOOK_LINK;
export const instagramLink = Env.REACT_APP_INSTAGRAM_LINK;
export const linkedInLink = Env.REACT_APP_LINKED_IN_LINK;

export const WIDGET_FORM_URL = 'https://api.leadconnectorhq.com/widget/form/';
export const WIDGET_BOOKING_URL =
  'https://api.leadconnectorhq.com/widget/booking/';
export const ROMAN_ID = '50AvXgnzHA4vHISzjvg7';
export const YURIY_ID = 'E8LmPXN1Smn9tGuQx1Tr';

export const PRICING_PLAN_UNIVERSAL_ID = 'kERd5xlNow6xzfuH314r';
export const PRICING_PLAN_BOOSTED_ID = 'o0rmSACICouZKHveLHBi';
export const PRICING_PLAN_SUPERCHARGED_ID = 'biw7ZUnb5PjdyNndBlsS';

export const UNIVERSAL = 'kERd5xlNow6xzfuH314r';
export const BOOSTED = 'o0rmSACICouZKHveLHBi';
export const SUPERCHARGED = 'biw7ZUnb5PjdyNndBlsS';

export const I_FRAME_FORM_STYLE = {
  border: 'none',
  width: '100%',
  height: 900,
};

export const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  unitDisplay: 'short',
  // These options are needed to round to whole numbers if that's what you want.
  //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
  //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
});

export const formatterDollarShort = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  notation: 'compact',
  compactDisplay: 'short',
  // These options are needed to round to whole numbers if that's what you want.
  //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
  //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
});

export const displayCentsInDollars = (cents) =>
  formatter.format(cents ? cents / 100 : 0);

export const displayCentsInDollarsShort = (cents) =>
  formatterDollarShort.format(cents ? cents / 100 : 0);

export function removeObjectWithId(arr, id) {
  return arr.filter((obj) => obj.id !== id);
}

function preloadImage(src) {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = function () {
      resolve(img);
    };
    img.onerror = img.onabort = function () {
      reject(src);
    };
    img.src = src;
  });
}

export default function useImagePreloader(imageList) {
  const [imagesPreloaded, setImagesPreloaded] = useState(false);

  useEffect(() => {
    let isCancelled = false;

    async function effect() {
      console.log('PRELOAD');

      if (isCancelled) {
        return;
      }

      const imagesPromiseList = [];
      for (const i of imageList) {
        imagesPromiseList.push(preloadImage(i));
      }

      await Promise.all(imagesPromiseList);

      if (isCancelled) {
        return;
      }

      setImagesPreloaded(true);
    }

    effect();

    return () => {
      isCancelled = true;
    };
  }, [imageList]);

  return { imagesPreloaded };
}

export const pathname = window.location.pathname;

export const pathnameStartWith = (path) =>
  pathname.startsWith(path) ? pathname : null;

export const formatDate = (iso, timezone) => {
  var date_str = iso,
    options = {
      timezone,
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    },
    formatted = new Date(date_str).toLocaleDateString('en-US', options);

  return formatted;
};

export const formatDateTime = (iso) => {
  var date_str = iso,
    options = {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      time: 'long',
    },
    formatted = new Date(date_str).toLocaleTimeString('en-US', options);

  return formatted;
};

export const formatTime = (iso) => {
  var date_str = iso,
    options = {
      time: 'short',
      hour: 'numeric',
      minute: 'numeric',
      // dayPeriod: 'narrow',
    },
    formatted = new Date(date_str).toLocaleTimeString('en-US', options);

  return formatted;
};

export const formatDateUTC = (iso) => {
  var date_str = iso,
    options = {
      timeZone: 'UTC',
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    },
    formatted = new Date(date_str).toLocaleDateString('en-US', options);

  return formatted;
};

export const APP_TYPE = process.env.NX_APP_TYPE;

export const isAdmin = process.env.NX_APP_TYPE === 'admin';
export const isPartner = process.env.NX_APP_TYPE === 'partner';
export const isSales = process.env.NX_APP_TYPE === 'sales';

export const HEADERS = {
  headers: {
    'Access-Control-Allow-Origin': '*',
    'app-version': 'web-version-1.0.0',
    'content-type': 'application/json',
  },
};

export const API_URL_PARTNER = process.env.REACT_APP_API_URL;
export const API_URL_ADMIN = process.env.REACT_APP_API_URL_ADMIN;
export const API_URL_SALES = process.env.REACT_APP_API_URL_SALES;
export const INITIAL_PAGINATION_PAGE = 1;
export const PAGINATION_LIMIT = 20;
export const handleShowPaginationButton = (arrayLength, page) =>
  arrayLength === PAGINATION_LIMIT * page;

export const handleSetUrlSearchParams = (
  key,
  value,
  setSearchParams,
  replace = false
) => {
  setSearchParams(
    (prevParams) => {
      if (value === null || value === undefined || value === '') {
        prevParams.delete(key);
      } else {
        prevParams.set(key, value);
      }
      return prevParams;
    },
    { replace }
  );
};

// export const handleSetUrlSearchParams = (
//   key,
//   value,
//   setSearchParams,
//   replace = false
// ) => {
//   setSearchParams(
//     (prevParams) => {
//       const newParams = new URLSearchParams(prevParams); // ✅ Always create a new instance

//       if (!value) {
//         newParams.delete(key);
//       } else {
//         newParams.set(key, value);
//       }

//       return newParams;
//     },
//     { replace }
//   ); // ✅ Use replace to prevent unnecessary history entries
// };

export const renderMonthName = (month) => {
  switch (month) {
    case 1:
      return 'Jan';
    case 2:
      return 'Feb';
    case 3:
      return 'Mar';
    case 4:
      return 'Apr';
    case 5:
      return 'May';
    case 6:
      return 'Jun';
    case 7:
      return 'Jul';
    case 8:
      return 'Aug';
    case 9:
      return 'Sep';
    case 10:
      return 'Oct';
    case 11:
      return 'Nov';
    case 12:
      return 'Dec';

    default:
      break;
  }
};

export const setDocumentTitle = (pageTitle) =>
  (document.title = `${pageTitle}`);

function clearNumber(value = '') {
  return value.replace(/\D+/g, '');
}

export function formatCreditCardNumber(value) {
  if (!value) {
    return value;
  }

  const issuer = Payment.fns.cardType(value);
  const clearValue = clearNumber(value);
  let nextValue;

  switch (issuer) {
    case 'amex':
      nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(
        4,
        10
      )} ${clearValue.slice(10, 15)}`;
      break;
    case 'dinersclub':
      nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(
        4,
        10
      )} ${clearValue.slice(10, 14)}`;
      break;
    default:
      nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(
        4,
        8
      )} ${clearValue.slice(8, 12)} ${clearValue.slice(12, 19)}`;
      break;
  }

  return nextValue.trim();
}

export function formatCVC(value, prevValue, allValues = {}) {
  const clearValue = clearNumber(value);
  let maxLength = 3;

  // if (allValues.number) {
  //   const issuer = Payment.fns.cardType(allValues.number);
  // }

  return clearValue.slice(0, maxLength);
}

export function formatExpirationDate(value) {
  const clearValue = clearNumber(value);

  if (clearValue.length >= 3) {
    return `${clearValue.slice(0, 2)}/${clearValue.slice(2, 4)}`;
  }

  return clearValue;
}

export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export const useDisableBodyScroll = (open) => {
  useEffect(() => {
    if (open) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
  }, [open]);
};

export const renderRole = (role) => {
  switch (role) {
    case 'admin':
      return 'Admin';
    case 'support':
      return 'Support';
    case 'partner':
      return 'Partner';
    case 'manager':
      return 'Manager';
    case 'salesAgent':
      return 'Sales Agent';
    case 'salesManager':
      return 'Sales Manager';
    case 'staff':
      return 'Staff';

    case 'marketing':
      return 'Marketing';

    default:
      break;
  }
};

export const downloadImage = (image, name) => {
  if (!image) return;
  var element = document.createElement('a');
  var file = new Blob([image], { type: 'image/*' });
  element.href = URL.createObjectURL(file);
  element.download = `${name || 'image'}.jpg`;
  element.click();
};

export const setTimeTo = (date, type) => {
  const dt = new Date(date);
  dt.setHours(type === 'start' ? 0 : 23);
  dt.setMinutes(type === 'start' ? 0 : 59);
  dt.setSeconds(type === 'start' ? 0 : 59);
  dt.setMilliseconds(type === 'start' ? 0 : 59);
  return dt;
};

export const isToday = (date) => {
  const today = new Date();
  return (
    date.getFullYear() === today.getFullYear() &&
    date.getMonth() === today.getMonth() &&
    date.getDate() === today.getDate()
  );
};

export function centsToDollars(cents) {
  // Divide cents by 100 to get dollars
  return cents / 100;
}

export function dollarsToCents(dollars) {
  // Multiply dollars by 100 to get cents
  return dollars * 100;
}

export const providers = {
  UBER_EATS: 'ubereats',
  GRUB_HUB: 'grubhub',
  DOORDASH: 'doordash',
  CHOW_NOW: 'chownow',
  GLORIA_FOOD: 'gloriafood',
  DELIVERY: 'delivery',
  EAT_STREET: 'eatstreet',
  MENUFY: 'menufy',
  GRUBHUBPOS: 'grubhubPos',
  DOORDASHPOS: 'doordashPos',
  LOCAL_FOR_YOU: 'localForYou',
};

export const renderProviderImage = (provider) => {
  switch (provider) {
    case providers.UBER_EATS:
      return uberLogo;
    case providers.GRUB_HUB:
      return grubhubLogo;
    case providers.DOORDASH:
      return doordashLogo;
    case providers.CHOW_NOW:
      return chowNowLogo;
    case providers.GLORIA_FOOD:
      return gloriaLogo;
    case providers.DELIVERY:
      return deliveryLogo;
    case providers.EAT_STREET:
      return eatstreetLogo;
    case providers.MENUFY:
      return menufyLogo;
    case providers.GRUBHUBPOS:
      return grubhubLogo;
    case providers.DOORDASHPOS:
      return doordashLogo;
    case providers.LOCAL_FOR_YOU:
      return localForYouLogo;
    default:
      break;
  }
};

export const vDriveProviders = {
  UBER: 'uber',
  // GRUB_HUB: 'grubhub',
  DOORDASH: 'doordash',
  RELAY: 'relay',
  ALLY: 'ally',
  CUTCATS: 'cutcats',
  DLIVRD: 'dlivrd',
  MOTOCLICK: 'motoclick',
};

export const renderVDriveImage = (provider) => {
  switch (provider) {
    case vDriveProviders.UBER:
      return uberLogo;
    // case providers.GRUB_HUB:
    //   return grubhubLogo;
    case vDriveProviders.DOORDASH:
      return doordashLogo;
    case vDriveProviders.RELAY:
      return relayLogo;
    case vDriveProviders.ALLY:
      return allyLogo;
    case vDriveProviders.CUTCATS:
      return cutcatsLogo;
    case vDriveProviders.DLIVRD:
      return dlivrdLogo;
    case vDriveProviders.MOTOCLICK:
      return motoclickLogo;

    default:
      break;
  }
};

export const renderVDriveProviderName = (provider) => {
  switch (provider) {
    case vDriveProviders.UBER:
      return 'Uber';
    case vDriveProviders.DOORDASH:
      return 'DoorDash';
    case vDriveProviders.RELAY:
      return 'Relay';
    case vDriveProviders.ALLY:
      return 'Ally';
    case vDriveProviders.CUTCATS:
      return 'CutCats';
    case vDriveProviders.DLIVRD:
      return 'Dlivrd';
    case vDriveProviders.MOTOCLICK:
      return 'MotoClick';

    default:
      break;
  }
};

export const renderProviderIcon = (provider) => {
  switch (provider) {
    case providers.UBER_EATS:
      return uberIcon;
    case providers.GRUB_HUB:
      return grubhubIcon;
    case providers.DOORDASH:
      return doordashIcon;
    case providers.CHOW_NOW:
      return chowNowIcon;
    case providers.GLORIA_FOOD:
      return gloriaIcon;
    case providers.DELIVERY:
      return deliveryIcon;
    case providers.EAT_STREET:
      return eatstreetIcon;
    case providers.MENUFY:
      return menufyIcon;
    case providers.GRUBHUBPOS:
      return grubhubIcon;
    case providers.DOORDASHPOS:
      return doordashIcon;
    case providers.LOCAL_FOR_YOU:
      return localForYouIcon;
    default:
      break;
  }
};

export const renderProviderName = (p) => {
  switch (p) {
    case providers.UBER_EATS:
      return 'Uber Eats';
    case providers.DOORDASH:
      return 'DoorDash';
    case providers.GRUB_HUB:
      return 'Grubhub';
    case providers.CHOW_NOW:
      return 'ChowNow';
    case providers.GLORIA_FOOD:
      return 'GloriaFood';
    case providers.DELIVERY:
      return 'Delivery.com';
    case providers.EAT_STREET:
      return 'EatStreet';
    case providers.MENUFY:
      return 'Menufy';
    case providers.GRUBHUBPOS:
      return 'GrubhubPos';
    case providers.DOORDASHPOS:
      return 'DoorDashPos';
    case providers.LOCAL_FOR_YOU:
      return 'Local For You';
  }
};

export function showStringIfNotNull(str, comma) {
  if (str) {
    return `${str}${comma ? ',' : ''}`;
  } else {
    return '';
  }
}

export function convert24HourTo12Hour(time) {
  // Split the time into hours and minutes
  let [hours, minutes] = time.split(':');

  // Convert hours to a number
  hours = parseInt(hours, 10);

  // Determine AM or PM suffix
  let suffix = hours >= 12 ? 'PM' : 'AM';

  // Convert hours from 24-hour to 12-hour format
  hours = hours % 12 || 12; // Convert '0' or '12' to '12'

  // Format hours and minutes as two-digit strings
  hours = hours.toString().padStart(2, '0');
  minutes = minutes.toString().padStart(2, '0');

  // Return the formatted time
  return `${hours}:${minutes} ${suffix}`;
}

export const renderDayOfWeek = (day) => {
  switch (day) {
    case 0:
      return 'Sunday';
    case 1:
      return 'Monday';
    case 2:
      return 'Tuesday';
    case 3:
      return 'Wednesday';
    case 4:
      return 'Thursday';
    case 5:
      return 'Friday';
    case 6:
      return 'Saturday';

    default:
      break;
  }
};

export const daysOfWeek = [
  { name: 'Sunday', value: 0 },
  { name: 'Monday', value: 1 },
  { name: 'Tuesday', value: 2 },
  { name: 'Wednesday', value: 3 },
  { name: 'Thursday', value: 4 },
  { name: 'Friday', value: 5 },
  { name: 'Saturday', value: 6 },
];

export const renderDayOfWeekFrom1 = (day) => {
  switch (day) {
    case 1:
      return 'Monday';
    case 2:
      return 'Tuesday';
    case 3:
      return 'Wednesday';
    case 4:
      return 'Thursday';
    case 5:
      return 'Friday';
    case 6:
      return 'Saturday';
    case 7:
      return 'Sunday';

    default:
      break;
  }
};

export const daysOfWeekFrom1 = [
  { name: 'Monday', value: 1 },
  { name: 'Tuesday', value: 2 },
  { name: 'Wednesday', value: 3 },
  { name: 'Thursday', value: 4 },
  { name: 'Friday', value: 5 },
  { name: 'Saturday', value: 6 },
  { name: 'Sunday', value: 7 },
];

export const times = [
  '00:00',
  '00:10',
  '00:20',
  '00:30',
  '00:40',
  '00:50',
  '01:00',
  '01:10',
  '01:20',
  '01:30',
  '01:40',
  '01:50',
  '02:00',
  '02:10',
  '02:20',
  '02:30',
  '02:40',
  '02:50',
  '03:00',
  '03:10',
  '03:20',
  '03:30',
  '03:40',
  '03:50',
  '04:00',
  '04:10',
  '04:20',
  '04:30',
  '04:40',
  '04:50',
  '05:00',
  '05:10',
  '05:20',
  '05:30',
  '05:40',
  '05:50',
  '06:00',
  '06:10',
  '06:20',
  '06:30',
  '06:40',
  '06:50',
  '07:00',
  '07:10',
  '07:20',
  '07:30',
  '07:40',
  '07:50',
  '08:00',
  '08:10',
  '08:20',
  '08:30',
  '08:40',
  '08:50',
  '09:00',
  '09:10',
  '09:20',
  '09:30',
  '09:40',
  '09:50',
  '10:00',
  '10:10',
  '10:20',
  '10:30',
  '10:40',
  '10:50',
  '11:00',
  '11:10',
  '11:20',
  '11:30',
  '11:40',
  '11:50',
  '12:00',
  '12:10',
  '12:20',
  '12:30',
  '12:40',
  '12:50',
  '13:00',
  '13:10',
  '13:20',
  '13:30',
  '13:40',
  '13:50',
  '14:00',
  '14:10',
  '14:20',
  '14:30',
  '14:40',
  '14:50',
  '15:00',
  '15:10',
  '15:20',
  '15:30',
  '15:40',
  '15:50',
  '16:00',
  '16:10',
  '16:20',
  '16:30',
  '16:40',
  '16:50',
  '17:00',
  '17:10',
  '17:20',
  '17:30',
  '17:40',
  '17:50',
  '18:00',
  '18:10',
  '18:20',
  '18:30',
  '18:40',
  '18:50',
  '19:00',
  '19:10',
  '19:20',
  '19:30',
  '19:40',
  '19:50',
  '20:00',
  '20:10',
  '20:20',
  '20:30',
  '20:40',
  '20:50',
  '21:00',
  '21:10',
  '21:20',
  '21:30',
  '21:40',
  '21:50',
  '22:00',
  '22:10',
  '22:20',
  '22:30',
  '22:40',
  '22:50',
  '23:00',
  '23:10',
  '23:20',
  '23:30',
  '23:40',
  '23:50',
];

// Define the function
export function firstWhereOrNull(array, test) {
  // Ensure the test argument is a function
  // if (typeof test !== 'function') {
  //   throw new TypeError('The test argument must be a function');
  // }

  // Iterate through the array
  for (let element of array) {
    // If the test function returns true for the current element, return the element
    if (test(element)) {
      return element;
    }
  }

  // If no element satisfies the test function, return null
  return null;
}

export const V_DRIVE_STATUS = {
  active: 'active',
  inactive: 'inactive',
  inProgress: 'inProgress',
  rejected: 'rejected',
};

export const setTimeStartEnd = (date, type) => {
  if (type === 'start') {
    return DateTime.fromJSDate(date).startOf('day').toUTC().toISO();
  }
  return DateTime.fromJSDate(date).endOf('day').toUTC().toISO();
};

export const dateIsToday = (dateToCheck) => {
  const date = DateTime.fromISO(dateToCheck).toISODate();
  const today = DateTime.local().toISODate();

  return date === today;
};

export const renderDateLabelWithToday = (from, to) => {
  const dateFrom = DateTime.fromISO(from).toFormat('MMM dd, yyyy');
  const dateTo = DateTime.fromISO(to).toFormat('MMM dd, yyyy');

  if (dateIsToday(from) && dateIsToday(to)) {
    return 'Today';
  } else return `${dateFrom} - ${dateTo}`;
};

export const RenderBrandType = (t) => {
  switch (t) {
    case 'vGrubs':
      return 'vGrubs';
    case 'RB':
      return 'Restaurant Brand';
    case 'TDOC':
      return 'TODC';
    case 'FF':
      return 'Future Foods';

    default:
      break;
  }
};

export function unGroupFundingTipSchedule(data) {
  return data.flatMap((day) =>
    day.times.map((time) => ({
      dayOfTheWeek: day.dayOfTheWeek,
      amount: time.amount,
      startTime: time.startTime,
      endTime: time.endTime,
    }))
  );
}

export function groupFundingTipSchedule(data) {
  return data.reduce((result, item) => {
    const dayIndex = result.findIndex(
      (day) => day.dayOfTheWeek === item.dayOfTheWeek
    );

    if (dayIndex > -1) {
      result[dayIndex].times.push({
        amount: item.amount,
        startTime: item.startTime,
        endTime: item.endTime,
      });
    } else {
      result.push({
        dayOfTheWeek: item.dayOfTheWeek,
        times: [
          {
            amount: item.amount,
            startTime: item.startTime,
            endTime: item.endTime,
          },
        ],
      });
    }

    return result;
  }, []);
}

export const isTimeFromEarlier = (timeFrom, timeTo) => {
  const [fromHours, fromMinutes] = timeFrom.split(':').map(Number);
  const [toHours, toMinutes] = timeTo.split(':').map(Number);

  if (fromHours < toHours) {
    return true;
  }
  if (fromHours === toHours && fromMinutes < toMinutes) {
    return true;
  }
  return false;
};
