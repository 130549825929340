import { motion } from 'framer-motion';
import styled from 'styled-components';

export const ModalStyled = styled(motion.div)`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: ${({ theme }) => theme.background};
  z-index: ${({ $zIndex }) => ($zIndex ? `${$zIndex + 1}` : '100')};
  text-align: ${({ $textAlign }) => ($textAlign ? $textAlign : 'center')};
  border-radius: 30px;
  width: ${({ $width }) => $width ?? '500px'};
  max-width: 90%;

  ${({ $modalOverflow }) => $modalOverflow && `overflow: ${$modalOverflow}`};

  /* overflow: hidden; */

  /* > div {
    padding: ${({ $logo, $successImage }) =>
    $logo || $successImage ? '110px 35px 35px 35px' : '35px'};
    max-height: 90vh;
    ${({ $header }) => $header && `padding-top: 135px;`}

    overflow: auto;
    ::-webkit-scrollbar {
      display: none;
    }
    -ms-overflow-style: none;
    scrollbar-width: none;
  } */

  > svg {
    /* width: 130px; */
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  @media (max-width: 800px) {
    max-width: calc(100% - 60px);

    /* padding: ${({ $logo, $successImage }) =>
      $logo || $successImage ? '110px 24px 24px 24px' : '24px'}; */

    /* ${({ $header }) => $header && `padding-top: 105px;`} */

    > svg {
      width: 100px;
    }
  }
`;

export const ModalScrollableStyled = styled(motion.div)`
  max-height: calc(90vh - 100px);

  /* @media (max-width: 800px) {
    padding: ${({ $logo, $vDriveLogo, $successImage }) =>
    $logo || $vDriveLogo || $successImage ? '70px 24px 24px 24px' : '24px'};
    ${({ $footer }) => $footer && `padding-bottom: 170px;`}
  } */

  overflow: ${({ $overflow }) => ($overflow ? $overflow : 'auto')};
  ::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
`;

export const ModalOverflowStyled = styled(motion.div)`
  padding: ${({ $logo, $vDriveLogo, $successImage }) =>
    $logo || $vDriveLogo || $successImage ? '110px 32px 32px 32px' : '32px'};

  ${({ footer, $numOfFloatingButtons }) =>
    footer &&
    `${
      $numOfFloatingButtons === 1
        ? `padding-bottom: 109px;`
        : `padding-bottom: 170px;`
    }`}

  overflow: ${({ $overflow }) => ($overflow ? $overflow : 'hidden')};

  @media (max-width: 800px) {
    padding: ${({ $logo, $vDriveLogo, $successImage }) =>
      $logo || $vDriveLogo || $successImage ? '110px 24px 24px 24px' : '24px'};
    ${({ footer, $numOfFloatingButtons }) =>
      footer &&
      `${
        $numOfFloatingButtons === 1
          ? `padding-bottom: 109px;`
          : `padding-bottom: 170px;`
      }`}
  }
`;

export const ModalOverlay = styled(motion.div)`
  background-color: ${({ theme }) => theme.darkGray}4D;
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: ${({ $zIndex }) => ($zIndex ? `${$zIndex}` : '99')};
`;

export const SuccessImage = styled(motion.img)`
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 250px;
`;

export const Image = styled(motion.img)`
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 150px;
`;

export const ModalHeader = styled.div`
  background-color: ${({ theme }) => theme.primary};
  /* position: absolute;
  left: 0;
  top: 0;
  right: 0; */
  height: 100px;
  border-radius: 30px 30px 0 0;
  padding: 0 32px;
  display: flex;
  /* align-items: center; */
  justify-content: center;
  flex-direction: column;
  text-align: left;

  @media (max-width: 800px) {
    height: 80px;
  }
`;

export const ModalFooter = styled.div`
  /* background-color: ${({ theme }) => theme.primary}; */
  background: linear-gradient(
    to top,
    rgba(255, 255, 255, 1) 30%,
    rgba(255, 255, 255, 0) 100%
  );
  background: -webkit-linear-gradient(
    bottom,
    rgba(255, 255, 255, 1) 30%,
    rgba(255, 255, 255, 0) 100%
  );
  z-index: 1;

  position: absolute;
  width: 100%;
  left: 0;
  bottom: 0;
  right: 0;
  border-radius: 30px;
  padding: 32px;
  @media (max-width: 800px) {
    /* height: 80px; */
  }
`;
