import {
  AdminDollarInput2,
  AdminInput,
  AdminSwitch,
  ArrowRed,
  Button,
  Disclaimer,
  Div,
  ErrorToast,
  H3,
  H5,
  Icon,
  Modal,
  ModalFullScreen,
  P,
  PaymentTabletIcon,
  PrinterIcon,
  Separator,
  SuccessToast,
  VDriveLogoSmall,
  VGrubsLogoSmall,
  VOrdersLogoSmall,
  api,
  displayCentsInDollars,
  displayCentsInDollarsShort,
  lightTheme,
} from '@vgrubs/components';
import { useCallback, useEffect, useState } from 'react';
import { EditServiceFee } from './EditServiceFee';

export const EditServices = ({
  salesConfig,
  modalIsOpen,
  setModalIsOpen,
  preferences,
  id,
  setLoading,
  setData,
}) => {
  const [serviceFeeModal, setServiceFeeModal] = useState(false);

  const [vDriveService, setVDriveService] = useState(false);
  const [vOrdersService, setVOrdersService] = useState(false);
  const [vGrubsService, setVGrubsService] = useState(false);

  const [vDriveDeliveryCharge, setVDriveDeliveryCharge] = useState(0);
  const [extraPrinters, setExtraPrinters] = useState('');
  const [extraTablets, setExtraTablets] = useState('');

  const [vOrdersMonthlySalesFee, setVOrdersMonthlySalesFee] = useState(null);

  useEffect(() => {
    if (salesConfig) {
      setVDriveService(salesConfig.vDriveService);
      setVOrdersService(salesConfig.vOrdersService);
      setVGrubsService(salesConfig.vGrubsService);
      if (salesConfig.vDriveService) {
        setVDriveDeliveryCharge(salesConfig.vDriveDeliveryCharge);
      }
      if (salesConfig.vOrdersService) {
        setVOrdersMonthlySalesFee(salesConfig.vOrdersMonthlySalesFee);
      }
      if (salesConfig.vGrubsService) {
        setExtraPrinters(salesConfig.extraPrinters);
        setExtraTablets(salesConfig.extraTablets);
      }
    }
  }, [modalIsOpen]);

  const handleVDriveDeliveryCharge = useCallback((val) => {
    setVDriveDeliveryCharge(val);
  }, []);

  async function handleEditServices() {
    if (Number(extraPrinters) !== Number(extraTablets))
      return ErrorToast(
        'The number of Printers must match the number of Tablets.'
      );

    if (vDriveService && !vOrdersService)
      return ErrorToast('vOrders service is required to use vDrive service.');

    if (vOrdersService && !vOrdersMonthlySalesFee)
      return ErrorToast('Please select a service fee to enable vOrders.');

    const body = {
      monthlyFeeId: vOrdersMonthlySalesFee?.id ?? -1,
      vDriveDeliveryChargeFee: vDriveService ? vDriveDeliveryCharge : -1,
      vGrubsService,
      extraTablets: Number(extraTablets) ?? null,
      extraPrinters: Number(extraPrinters) ?? null,
    };

    try {
      setLoading(true);
      const { data } = await api.put(`/v1/locations/${id}/sales-config`, body);
      SuccessToast(data.message);
      setModalIsOpen(false);

      setData((prev) => {
        return {
          ...prev,
          salesConfig: {
            ...prev.salesConfig,
            vGrubsService,
            vDriveService,
            vOrdersService,
            vOrdersMonthlySalesFee,
            extraPrinters,
            extraTablets,
            vDriveDeliveryCharge,
          },
        };
      });
    } catch (error) {
      ErrorToast(error?.response?.data?.message);
    } finally {
      setLoading(false);
    }
  }

  const handleChangeDeliveryFee = (v) => {
    if (!vDriveService) setVDriveService(true);
    handleVDriveDeliveryCharge(v);
  };

  const handleChangeServiceFee = (v) => {
    if (!vOrdersService) setVOrdersService(true);
    setVOrdersMonthlySalesFee(v);
  };

  const handleChangeTablets = (e) => {
    let value = e.target.value;
    // Allow only numbers
    if (!/^\d*$/.test(value)) return;
    // Prevent leading zeros (but allow single "0")
    if (value.length > 1 && value.startsWith('0')) return;
    // Update state or handle value
    setExtraTablets(value);
  };

  const handleChangePrinters = (e) => {
    let value = e.target.value;
    // Allow only numbers
    if (!/^\d*$/.test(value)) return;
    // Prevent leading zeros (but allow single "0")
    if (value.length > 1 && value.startsWith('0')) return;
    // Update state or handle value
    setExtraPrinters(value);
  };

  const handleChangeVDriveService = (v) => {
    setVDriveService(v);
    setVOrdersMonthlySalesFee(null);
    setVDriveDeliveryCharge(0);
  };

  const handleChangeVOrderService = (v) => {
    setVOrdersService(v);
    setVOrdersMonthlySalesFee(null);
  };

  const handleChangeVGrubsService = (v) => {
    setVGrubsService(v);
  };

  useEffect(() => {
    if (vOrdersService && modalIsOpen) setServiceFeeModal(true);
  }, [vOrdersService]);

  const handleChange = (event) => {
    let value = event.target.value;

    // Allow only numbers
    if (!/^\d*$/.test(value)) return;

    // Prevent leading zeros (but allow single "0")
    if (value.length > 1 && value.startsWith('0')) return;

    // Update state or handle value
    console.log(value);
  };

  return (
    <>
      <Modal
        isOpen={modalIsOpen}
        setIsOpen={setModalIsOpen}
        header="Services that client is interested"
        width="1000px"
        logo={false}
        textAlign="left"
      >
        <Div $flex $gap="50px" $gapSM="30px" $columnSM>
          <Div $width="50%" $widthSM="100%">
            <SwitchComp
              Logo={VDriveLogoSmall}
              text="vDrive"
              state={vDriveService}
              setState={handleChangeVDriveService}
            />
            <Div $box $p="20px" $mb="30">
              <Div $flex $alignCenter $between>
                <H5 $m="0">Delivery Fee</H5>
                <Div>
                  <H3 $right $m="0">
                    <span>
                      {displayCentsInDollars(725 - vDriveDeliveryCharge)}
                    </span>
                  </H3>
                  <Disclaimer $color={lightTheme.lightGray} $right $m="0">
                    Per Delivery
                  </Disclaimer>
                </Div>
              </Div>
              <Separator $m="16px 0" />
              <P $m="0" $color={lightTheme.lightGray}>
                This delivery fee doesn’t factor in the savings that you get
                from decreasing your delivery app commission by 15%
              </P>
              <Separator $m="16px 0" />
              <Div $flex $alignCenter $between>
                <P $m="0">How much you want to charge Diner for delivery?</P>
                <Div $width="100px" $flexNone>
                  <AdminDollarInput2
                    onValueChange={handleChangeDeliveryFee}
                    value={vDriveDeliveryCharge}
                    $textCenter
                    max={725}
                  />
                </Div>
              </Div>
            </Div>
            <SwitchComp
              Logo={VOrdersLogoSmall}
              text="vOrders"
              state={vOrdersService}
              setState={handleChangeVOrderService}
            />

            <Div
              $box
              $p="20px"
              $pointer
              onClick={() => setServiceFeeModal(true)}
            >
              <Div $flex $alignCenter $between>
                <Div>
                  <H5 $m="0">Service Fee</H5>
                  <P $m="0">Per Month</P>
                </Div>
                <Div $flex $alignCenter $gap="12px">
                  <Div $width="100px" $flexNone>
                    {vOrdersMonthlySalesFee && (
                      <Div
                        $outlineBox
                        $flex
                        $center
                        $height="45px"
                        $borderRadius="100px"
                      >
                        <H3 $m="0">
                          <span>
                            {displayCentsInDollarsShort(
                              vOrdersMonthlySalesFee.fee
                            )}
                          </span>
                        </H3>
                      </Div>
                    )}
                  </Div>
                  <Icon>
                    <ArrowRed />
                  </Icon>
                </Div>
              </Div>
            </Div>
          </Div>
          <Div $width="50%" $widthSM="100%" $flex $column $between>
            <Div>
              <SwitchComp
                Logo={VGrubsLogoSmall}
                text="vGrubs"
                state={vGrubsService}
                setState={handleChangeVGrubsService}
              />

              <Div $box $p="20px" $mb="30">
                <Div $flex $alignCenter $between $gap="10px">
                  <Div $flex $alignCenter $gap="10px">
                    <PaymentTabletIcon width="30" height="30" />
                    <H5 $m="0">Additional Tablets</H5>
                  </Div>

                  <Div>
                    <H3 $right $m="0">
                      <span>
                        {displayCentsInDollars(preferences.extraTabletPrice)}
                      </span>
                    </H3>
                    <Disclaimer $color={lightTheme.lightGray} $right $m="0">
                      Per Month
                    </Disclaimer>
                  </Div>
                </Div>

                <Separator $m="16px 0" />
                <Div $flex $alignCenter $between $gap="10px">
                  <Div>
                    <P $m="0">Do you need extra tablets?</P>
                    <P $color={lightTheme.lightGray} $m="0">
                      Additional one is $10/mo.
                    </P>
                  </Div>
                  <Div $width="100px" $flexNone>
                    <AdminInput
                      value={extraTablets}
                      onChange={handleChangeTablets}
                      $textCenter
                      type="number"
                      placeholder="0"
                    />
                  </Div>
                </Div>
              </Div>

              <Div $box $p="20px" $mb="30">
                <Div $flex $alignCenter $between $gap="10px">
                  <Div $flex $alignCenter $gap="10px">
                    <PrinterIcon width="30" height="30" />
                    <H5 $m="0">Additional Printers</H5>
                  </Div>

                  <Div>
                    <H3 $right $m="0">
                      <span>
                        {displayCentsInDollars(preferences.extraPrinterPrice)}
                      </span>
                    </H3>
                    <Disclaimer $color={lightTheme.lightGray} $right $m="0">
                      Per Month
                    </Disclaimer>
                  </Div>
                </Div>

                <Separator $m="16px 0" />
                <Div $flex $alignCenter $between $gap="10px">
                  <Div>
                    <P $m="0">Do you need extra printers?</P>
                    <P $color={lightTheme.lightGray} $m="0">
                      Additional one is $10/mo.
                    </P>
                  </Div>
                  <Div $width="100px" $flexNone>
                    <AdminInput
                      value={extraPrinters}
                      onChange={handleChangePrinters}
                      $textCenter
                      type="number"
                      placeholder="0"
                    />
                  </Div>
                </Div>
              </Div>
            </Div>
            <Div $flex $gap="16px" $column>
              <Button $selected $w="100%" onClick={handleEditServices}>
                Save
              </Button>
              <Button $selected $gray $w="100%">
                Cancel
              </Button>
            </Div>
          </Div>
        </Div>
      </Modal>
      <EditServiceFee
        modalIsOpen={serviceFeeModal}
        setModalIsOpen={setServiceFeeModal}
        vOrdersMonthlySalesFee={vOrdersMonthlySalesFee}
        setVOrdersMonthlySalesFee={setVOrdersMonthlySalesFee}
        vDriveService={vDriveService}
        preferences={preferences}
        handleChangeServiceFee={handleChangeServiceFee}
      />
    </>
  );
};

const SwitchComp = ({ state, setState, Logo, text }) => {
  return (
    <Div $flex $alignCenter $between $mb="16">
      <Div $flex $alignCenter $gap="20px" $gapSM="10px">
        <Div $desktop>
          <Logo width="75" height="75" />
        </Div>
        <Div $mobile>
          <Logo width="50" height="50" />
        </Div>

        <H3 $m="0">{text}</H3>
      </Div>
      <AdminSwitch checked={state} onChange={() => setState((prev) => !prev)} />
    </Div>
  );
};
