import {
  AdminDollarInput,
  Button,
  Div,
  Modal,
  P,
  Separator,
} from '@vgrubs/components';
import { useEffect, useState } from 'react';

export const EditEarningPlan = ({
  planAmount,
  handleSetAmount,
  modalIsOpen,
  setModalIsOpen,
  label,
  handleDeletePlan,
}) => {
  const [amount, setAmount] = useState(0);

  useEffect(() => {
    setAmount(planAmount);
  }, [modalIsOpen]);

  return (
    <Modal
      isOpen={modalIsOpen}
      setIsOpen={setModalIsOpen}
      logo={false}
      header="Edit Earning Plan"
    >
      <Separator />
      <Div $height="45px" $heightSM="45px" $flex $alignCenter>
        <P $bold $m="0">
          {label}
        </P>
      </Div>
      <Separator />

      <Div $flex $alignCenter $between $m="16px 0">
        <P $m="0">Add Amount</P>
        <Div $maxWidth="120px">
          <AdminDollarInput
            prefix="$"
            value={amount / 100}
            onChange={(_, value) => setAmount(value * 100)}
          />
        </Div>
      </Div>

      <Div $flex $gap="20px" $mt="24">
        <Div $width="50%">
          <Button
            $selected
            $w="100%"
            onClick={() => {
              handleSetAmount(amount);
              setModalIsOpen(false);
            }}
          >
            Confirm
          </Button>
        </Div>
        <Div $width="50%">
          <Button
            $selected
            $gray
            $w="100%"
            onClick={() => {
              handleDeletePlan();
              setModalIsOpen(false);
            }}
          >
            Delete
          </Button>
        </Div>
      </Div>
    </Modal>
  );
};
