import {
  AdminHeader,
  AdminInput,
  AdminRolePicker,
  AdminSpinner,
  AnimatedDiv,
  Disclaimer,
  Div,
  ErrorToast,
  H3,
  H5,
  HeaderIconWrapper,
  Img,
  PlusIcon,
  agentImage,
  api,
  lightTheme,
  renderRole,
} from '@vgrubs/components';
import debouce from 'lodash.debounce';
import { useEffect, useMemo, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { UsersAndAccessAddModal } from './UsersAndAccessAdd';
import { UsersAndAccessDeleteModal } from './UsersAndAccessDelete';
import { UsersAndAccessDetailsModal } from './UsersAndAccessDetails';
import { UsersAndAccessEditModal } from './UsersAndAccessEdit';
import { useNavigate } from 'react-router';

export const UsersAndAccess = () => {
  const [addUserModal, setAddUserModal] = useState(false);
  const [userDetailsModal, setUserDetailsModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [editModal, setEditModal] = useState(false);

  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [ref, inView] = useInView();

  const [query, setQuery] = useState('');
  const [role, setRole] = useState(null);

  async function getAllUsers(search, page, hasMore) {
    if (!hasMore) return;

    try {
      setLoading(true);
      const { data } = await api.get(`/v1/users`, {
        params: {
          limit: 20,
          page: page,
          query: query.length > 0 ? query : null,
          role,
        },
      });

      if (!search) {
        if (data.data[0]) {
          setHasMore(true);
          setUsers((prevItems) => [...prevItems, ...data.data]);
        } else {
          setHasMore(false);
        }
      } else {
        if (data.data[0]) {
          setHasMore(true);
          setUsers(() => [...data.data]);
        } else {
          setUsers(() => [...data.data]);
          setHasMore(false);
        }
      }

      setPage((prevPage) => prevPage + 1);
      setLoading(false);
    } catch (error) {
      ErrorToast(error?.response?.data?.message);
      setLoading(false);
    }
  }

  useEffect(() => {
    setHasMore(true);
    setPage(1);
    getAllUsers(true, 1, true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query, role]);

  useEffect(() => {
    if (inView && users.length > 0) {
      getAllUsers(false, page, hasMore);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inView]);

  const handleOnDelete = (user) => {
    // setUserDetailsModal(false);
    setDeleteModal(user);
  };

  const handleOnEdit = (user) => {
    // setUserDetailsModal(false);
    setEditModal(user);
  };

  const handleChange = async (e) => {
    setQuery(e.target.value);
  };

  const debouncedResults = useMemo(() => {
    return debouce(handleChange, 500);
  }, []);

  useEffect(() => {
    return () => {
      debouncedResults.cancel();
    };
  });

  return (
    <>
      {loading && <AdminSpinner />}

      <AdminHeader>
        <Div $flex $gap="32px" $gapSM="20px">
          <AdminRolePicker onChange={setRole} value={role} />

          <HeaderIconWrapper
            $opened={false}
            onClick={() => setAddUserModal(true)}
          >
            <PlusIcon />
          </HeaderIconWrapper>
        </Div>
      </AdminHeader>
      <Div $p="40px 0" $flex $alignCenter $between>
        <Div $desktop>
          <H3 $m="0">All Users</H3>
        </Div>
        <AdminInput
          maxWidth="350px"
          maxWidthSM="100%"
          search
          placeholder="Search User"
          onChange={debouncedResults}
        />
      </Div>
      {users.map((user, i) => {
        return (
          <UserComponent
            key={i}
            user={user.user}
            setUserDetailsModal={setUserDetailsModal}
          />
        );
      })}

      {users.length === 0 && (
        <Div
          $flex
          $center
          $column
          $height="calc(70vh - 300px)"
          $heightSM="calc(60vh - 300px)"
        >
          <Img
            src={agentImage}
            alt="restaurant"
            $width="250px"
            $widthSM="150px"
          />
          <H5 $mt="16">No users were found</H5>
        </Div>
      )}

      <div ref={ref}></div>
      <UsersAndAccessAddModal
        modalIsOpen={addUserModal}
        setModalIsOpen={setAddUserModal}
        setLoading={setLoading}
        setUsers={setUsers}
      />
      <UsersAndAccessDetailsModal
        modalIsOpen={userDetailsModal}
        setModalIsOpen={setUserDetailsModal}
        handleOnDelete={handleOnDelete}
        handleOnEdit={handleOnEdit}
      />
      <UsersAndAccessDeleteModal
        setLoading={setLoading}
        setModalIsOpen={setDeleteModal}
        modalIsOpen={deleteModal}
        setUsers={setUsers}
        setUserDetailsModal={setUserDetailsModal}
      />
      <UsersAndAccessEditModal
        modalIsOpen={editModal}
        setModalIsOpen={setEditModal}
        setLoading={setLoading}
        setUsers={setUsers}
        setUserDetailsModal={setUserDetailsModal}
      />
    </>
  );
};

const UserComponent = ({ user, setUserDetailsModal }) => {
  const navigate = useNavigate();

  const handleUserClick = () => {
    if (user.role === 'salesAgent') {
      navigate(`/users-and-access/agent/${user.id}`);
    } else {
      setUserDetailsModal(user);
    }
  };

  return (
    <AnimatedDiv
      $pointer
      $box
      $p="16px 24px"
      $mb="16"
      onClick={handleUserClick}
    >
      <H5 $mb="4">{user.firstName + ' ' + user.lastName}</H5>
      <Disclaimer $color={lightTheme.lightGray} $m="0" $capitalize>
        {renderRole(user.role)}
      </Disclaimer>
    </AnimatedDiv>
  );
};
