import {
  AdminInput,
  AdminSwitch,
  Button,
  ConnectGrubconModal,
  Div,
  ErrorToast,
  GrubconConnect,
  H5,
  Modal,
  Separator,
  SuccessToast,
  api,
} from '@vgrubs/components';
import { useEffect, useState } from 'react';
import { EditReferralModal, ReferralComponent } from './ReferralEdit';

export const LocationEdit = ({
  modalIsOpen,
  setModalIsOpen,
  setLoading,
  setData,
  data,
  setChangeAddressModal,
}) => {
  const [locationName, setLocationName] = useState('');
  const [bracketPercentage, setBracketPercentage] = useState('');
  const [menuManager, setMenuManager] = useState('');
  const [menuConsultant, setMenuConsultant] = useState('');
  const [menuAssistant, setMenuAssistant] = useState('');
  const [menuPictureAssistant, setMenuPictureAssistant] = useState('');

  const [referralModal, setReferralModal] = useState(false);

  const [toggle, setToggle] = useState(true);

  const [grubconConnectModal, setGrubconConnectModal] = useState(false);
  const [sip, setSip] = useState(null);
  const [crmApiKey, setCrmApiKey] = useState(null);
  const [referralUser, setReferralUser] = useState(null);
  const [achCustomerId, setAchCustomerId] = useState('');

  const isGrubconConnected = crmApiKey !== null;

  useEffect(() => {
    if (data) {
      setLocationName(data.name);
      setBracketPercentage(data.bracketPercentage);
      setToggle(data.earningsAvailable);
      setSip(data.sip);
      setCrmApiKey(data.crmApiKey);
      setReferralUser(data.referral);
      setAchCustomerId(data.achCustomerId);
      setMenuConsultant(data.menuConsultant ?? '');
      setMenuManager(data.menuInfo.manager ?? '');
      setMenuAssistant(data.menuInfo.assistant ?? '');
      setMenuPictureAssistant(data.menuInfo.pictureAssistant ?? '');
    }
  }, [modalIsOpen]);

  async function handleAddEdit() {
    var loc = {
      name: locationName,
      percentage: bracketPercentage,
      earningsAvailable: toggle,
      sip,
      crmApiKey,
      referralId: referralUser?.id || null,
      achCustomerId,
      menuConsultant,
      menuAssistant,
      menuPictureAssistant,
      menuManager,
    };

    if (!sip?.server || !sip?.user || !sip?.password || !sip?.phoneNumber) {
      delete loc.sip;
    }

    try {
      setLoading(true);

      const res = await api.put(`/v1/locations/${data.id}`, loc);
      setData((prev) => {
        return {
          ...prev,
          name: locationName,
          bracketPercentage,
          earningsAvailable: toggle,
          sip,
          crmApiKey,
          referral: referralUser,
          menuConsultant,
          menuInfo: {
            manager: menuManager,
            assistant: menuAssistant,
            pictureAssistant: menuPictureAssistant,
          },
        };
      });
      SuccessToast(res.data.message);
      setModalIsOpen(false);

      setLoading(false);
    } catch (error) {
      ErrorToast(error?.response?.data?.message);
      setLoading(false);
    }
  }

  // function handleSubmit(e) {
  //   e.preventDefault();

  //   // if (!brandName) return ErrorToast('Please enter Brand Name');
  //   // if (!image) return ErrorToast('Please upload Brand Image');

  //   handleAddEdit();
  // }

  return (
    <>
      <Modal
        isOpen={modalIsOpen}
        setIsOpen={setModalIsOpen}
        logo={false}
        header="Edit Location"
      >
        <Div $flex $column $gap="24px">
          <AdminInput
            label="Location Name"
            placeholder="Enter here"
            value={locationName}
            onChange={(e) => setLocationName(e.target.value)}
            $textCenter
          />

          <AdminInput
            label="Bracket Percentage"
            placeholder="Enter here"
            value={bracketPercentage}
            onChange={(e) => setBracketPercentage(e.target.value)}
            $textCenter
          />

          <AdminInput
            tooltip="The GoCardless Customer ID will be used for processing client payments related to outstanding balances on the vDrive credit card. A 2% fee will be applied for each balance top-up. Payments will be automatically charged every Monday and Thursday night."
            $textCenter
            label="ACH Customer ID"
            placeholder="Enter here"
            value={achCustomerId}
            onChange={({ target }) => setAchCustomerId(target.value)}
          />

          <AdminInput
            label="Menu Manager"
            placeholder="Enter here"
            value={menuManager}
            onChange={(e) => setMenuManager(e.target.value)}
            $textCenter
          />

          <AdminInput
            label="Menu Consultant"
            placeholder="Enter here"
            value={menuConsultant}
            onChange={(e) => setMenuConsultant(e.target.value)}
            $textCenter
          />

          <AdminInput
            label="Menu Assistant"
            placeholder="Enter here"
            value={menuAssistant}
            onChange={(e) => setMenuAssistant(e.target.value)}
            $textCenter
          />

          <AdminInput
            label="Menu Picture Assistant"
            placeholder="Enter here"
            value={menuPictureAssistant}
            onChange={(e) => setMenuPictureAssistant(e.target.value)}
            $textCenter
          />
        </Div>
        <Separator $m="24px 0" />
        <Div $flex $alignCenter $between>
          <H5 $m="0" $bold>
            Earnings Available
          </H5>

          <AdminSwitch
            checked={toggle}
            onChange={() => setToggle((prev) => !prev)}
          />
        </Div>
        <Separator $m="24px 0" />

        <ReferralComponent
          referral={referralUser}
          setModalIsOpen={setReferralModal}
        />

        <GrubconConnect
          isGrubconConnected={isGrubconConnected}
          setGrubconConnectModal={setGrubconConnectModal}
          setSip={setSip}
          setCrmApiKey={setCrmApiKey}
        />

        <Button
          $gray
          $w="100%"
          $mt="50"
          $mtSM="30"
          onClick={() => {
            setChangeAddressModal(true);
            setModalIsOpen(false);
          }}
        >
          Change Location Address
        </Button>

        <Button
          $selected
          $w="100%"
          $mt="16"
          // $mtSM="30"
          onClick={() => handleAddEdit()}
        >
          Submit
        </Button>
      </Modal>
      <ConnectGrubconModal
        modalIsOpen={grubconConnectModal}
        setModalIsOpen={setGrubconConnectModal}
        setSip={setSip}
        setCrmApiKey={setCrmApiKey}
        sipForEdit={sip}
        crmApiKeyForEdit={crmApiKey}
        type={isGrubconConnected ? 'edit' : 'add'}
      />
      <EditReferralModal
        modalIsOpen={referralModal}
        setModalIsOpen={setReferralModal}
        setReferralUser={setReferralUser}
        referralUser={referralUser}
      />
    </>
  );
};
