import {
  Button,
  Disclaimer,
  Div,
  H5,
  displayCentsInDollars,
  lightTheme,
} from '@vgrubs/components';
import { DateTime } from 'luxon';
import React from 'react';

export const ClientPaymentComponent = ({ payment, retryPayment }) => {
  const renderPriceColor = (status) => {
    switch (status) {
      case 'completed':
        return lightTheme.green;
      case 'failed':
        return lightTheme.primary;
      default:
        return '';
    }
  };

  const renderStatusColor = (status) => {
    switch (status) {
      case 'failed':
        return lightTheme.primary;
      default:
        return '';
    }
  };

  const handleRetry = () => {
    retryPayment(payment?.id);
  };

  return (
    <Div $box $p="12px 24px" $pSM="12px" $mb="8">
      <Div $flex $between>
        <Div $flex $column $gap="4px">
          <H5 $m="0">
            {payment.owner.firstName} {payment.owner.lastName}
          </H5>
          <Disclaimer $m="0">Transaction ID: #{payment.id}</Disclaimer>
          <Disclaimer $m="0">{payment.location.name}</Disclaimer>
        </Div>
        <Div $flex $alignCenter $gap="32px">
          {payment.status === 'failed' && (
            <Div $desktop>
              <Button $selected $small onClick={handleRetry}>
                Retry Payment
              </Button>
            </Div>
          )}

          <Div $flex $column $gap="4px">
            <H5 $m="0" $right $color={renderPriceColor(payment.status)}>
              {displayCentsInDollars(payment.amount)}
            </H5>
            <Disclaimer $right $m="0">
              {DateTime.fromISO(payment.createdAt).toFormat('MMM dd, yyyy')}
            </Disclaimer>
            <Disclaimer
              $right
              $m="0"
              $capitalize
              $color={renderStatusColor(payment.status)}
            >
              {payment.status}
            </Disclaimer>
          </Div>
        </Div>
      </Div>
      {payment.status === 'failed' && (
        <Div $mobile $mt="16" $flex $center>
          <Button $selected $small onClick={handleRetry}>
            Retry Payment
          </Button>
        </Div>
      )}
    </Div>
  );
};
