import {
  ADMIN_ADD_MANUAL_ORDER,
  AdminBrandsPicker,
  AdminDatePicker,
  AdminHeader,
  AdminSelect,
  AdminSingleLocationPicker,
  AdminSpinner,
  AnimatedDiv,
  Div,
  ErrorToast,
  H3,
  H5,
  HeaderIconWrapper,
  Img,
  P,
  PlusIcon,
  api,
  dateIsToday,
  setTimeStartEnd,
  handleSetUrlSearchParams,
  lightTheme,
  menuItems,
  read,
  store,
  useOrders,
  renderDateLabelWithToday,
  RefreshIcon,
} from '@vgrubs/components';
import { DateTime } from 'luxon';
import { useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { Link, useSearchParams } from 'react-router-dom';
import { OrderComponent } from './OrderComponent';
import { OrderDetails } from './OrderDetails';
import { OrderHistoryComponent } from './OrderHistoryComponent';

const orderStatusOptions = [
  { name: 'Active', value: 'active' },
  { name: 'Completed', value: 'completed' },
  { name: 'Canceled', value: 'cancelled' },
  { name: 'History', value: 'history' },
];

const typeOptions = (
  <optgroup label="Select Status">
    {orderStatusOptions.map((r, i) => (
      <option value={r.value} key={i}>
        {r.name}
      </option>
    ))}
  </optgroup>
);

export const Orders = () => {
  const { dataOrders, setDataOrders, dataHistory, setDataHistory } = useOrders(
    (state) => state
  );

  const [loading, setLoading] = useState(false);

  const limit = 20;
  const [hasMore, setHasMore] = useState(true);
  const [ref, inView] = useInView();

  const locationLSNode = 'vgrubs-admin-orders-location';
  const brandsLSNode = 'vgrubs-admin-orders-brands';
  const locationFromLS = JSON.parse(read(locationLSNode));

  const [selectedLocation, setSelectedLocation] = useState(
    locationFromLS || null
  );

  const [selectedBrands, setSelectedBrands] = useState([]);

  const today = new Date();

  const INITIAL_DATE = [
    setTimeStartEnd(today, 'start'),
    setTimeStartEnd(today, 'end'),
  ];

  const [dateInput, setDateInput] = useState([today, today]);

  const [searchParams, setSearchParams] = useSearchParams();

  const status = searchParams.get('status') ?? orderStatusOptions[0].value;
  const from = searchParams.get('from') ?? INITIAL_DATE[0];
  const to = searchParams.get('to') ?? INITIAL_DATE[1];
  const page = Number(searchParams.get('page')) || 1;

  const setDate = (date) => {
    handleSetUrlSearchParams('from', date[0], setSearchParams);
    handleSetUrlSearchParams('to', date[1], setSearchParams);
  };

  const setPage = (page) => {
    handleSetUrlSearchParams('page', page, setSearchParams, true);
  };

  async function getOrders(
    selectedBrands,
    isWithScroll,
    hasMore,
    page,
    selectedLocation,
    isFilter
  ) {
    if (!hasMore) return;

    const locs = selectedLocation ? `&locationIds=${selectedLocation?.id}` : '';

    const brnds = selectedLocation
      ? selectedBrands.map(({ id }) => `&brandIds=${id}`).join('&')
      : '';

    const queryStringNoLocations = new URLSearchParams({
      from,
      to,
      page,
      limit,
      ordersFind: status,
      sortByDate: status === 'active' ? 'asc' : 'desc',
    }).toString();

    const queryStringNoLocationsPerDate = new URLSearchParams({
      from,
      to,
      ordersFind: status,
      sortByDate: status === 'active' ? 'asc' : 'desc',
    }).toString();

    try {
      setLoading(true);
      const { data } = await api.get(
        `/v1/orders?${queryStringNoLocations}${locs}${brnds}`
      );

      if (status === 'history') {
        const res = await api.get(
          `/v1/orders/sales-per-day?${queryStringNoLocationsPerDate}&${locs}&${brnds}`
        );

        setDataHistory(res.data.data);

        let orders = [];

        if (isFilter) {
          orders = data.data;
        } else {
          orders = [...dataOrders, ...data.data];
        }

        const dataByDates = [...res.data.data];

        // Group orders by date
        const ordersByDate = {};
        orders.forEach((order) => {
          const date = DateTime.fromISO(order.date)
            .setZone(order.timeZone)
            .toISODate();

          if (!ordersByDate[date]) {
            ordersByDate[date] = [];
          }
          ordersByDate[date].push(order);
        });

        // Merge orders into dataByDates
        dataByDates.forEach((dateNode) => {
          const date = dateNode.salesDay;
          dateNode.orders = ordersByDate[date] || [];
        });

        setDataHistory(dataByDates);
      }

      if (data.data.length === limit) {
        setPage(Number(page) + 1);
        setHasMore(true);
      } else {
        setHasMore(false);
      }

      if (isFilter) {
        setDataOrders(data.data);
      } else {
        setDataOrders([...dataOrders, ...data.data]);
      }
      setLoading(false);
    } catch (error) {
      ErrorToast(error?.response?.data?.message);
      setLoading(false);
    }
  }

  const handleDateChange = (date) => {
    setDateInput(date);
  };

  const handleDateApply = () => {
    setDataOrders([]);

    if (dateInput.length < 2) {
      setDate([
        setTimeStartEnd(dateInput[0], 'start'),
        setTimeStartEnd(dateInput[0], 'end'),
      ]);
      setDateInput([dateInput[0], dateInput[0]]);
    } else {
      setDate([
        setTimeStartEnd(dateInput[0], 'start'),
        setTimeStartEnd(dateInput[1], 'end'),
      ]);
    }
  };

  const ordersRefresh = () => {
    if (selectedLocation) {
      setPage(1);
      setDataOrders([]);
      setDataHistory([]);
      setHasMore(true);
      getOrders(selectedBrands, false, true, 1, selectedLocation, true);
    }
  };

  useEffect(() => {
    if (selectedLocation) {
      setPage(1);
      setDataOrders([]);
      setDataHistory([]);
      setHasMore(true);
      getOrders(selectedBrands, false, true, 1, selectedLocation, true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [from, to, status, selectedLocation]);

  const handleLocationsSubmit = (l) => {
    setSelectedBrands([]);
    store(locationLSNode, JSON.stringify(l));
  };

  const handleBrandsSubmit = () => {
    setDataOrders([]);
    setDataHistory([]);
    setPage(1);
    setHasMore(true);
    getOrders(selectedBrands, false, true, 1, selectedLocation, true);
    store(brandsLSNode, JSON.stringify(selectedBrands));
  };

  useEffect(() => {
    if (inView && dataOrders.length > 0) {
      getOrders(selectedBrands, true, hasMore, page, selectedLocation, false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inView]);

  return (
    <>
      <AdminHeader title={'Orders'}>
        <Div $flex $gap="20px" $gapSM="20px">
          <HeaderIconWrapper $opened={false} onClick={ordersRefresh}>
            <RefreshIcon />
          </HeaderIconWrapper>
          <Link to={ADMIN_ADD_MANUAL_ORDER}>
            <HeaderIconWrapper $opened={false}>
              <PlusIcon />
            </HeaderIconWrapper>
          </Link>
          {status === 'history' && (
            <AdminDatePicker
              mode="range"
              value={dateInput}
              onChange={handleDateChange}
              click={handleDateApply}
            />
          )}
        </Div>
      </AdminHeader>
      {loading && <AdminSpinner />}
      <AnimatedDiv
        $justifyEnd
        $flex
        $between
        $alignCenter
        $gap="32px"
        $gapSM="16px"
        $columnSM
        $borderBottom
        $p="32px 0"
        $mb="32"
      >
        <Div $width="33%" $widthSM="100%">
          <AdminSingleLocationPicker
            selectedLocation={selectedLocation}
            setSelectedLocation={setSelectedLocation}
            handleSubmit={(l) => handleLocationsSubmit(l)}
          />
        </Div>
        <Div $width="33%" $widthSM="100%">
          <AdminBrandsPicker
            selectedLocations={[selectedLocation]}
            selectedBrans={selectedBrands}
            setSelectedBrands={setSelectedBrands}
            handleSubmit={() => handleBrandsSubmit()}
          />
        </Div>
        <Div $width="33%" $widthSM="100%">
          <AdminSelect
            value={status}
            options={typeOptions}
            onChange={({ target }) => {
              setPage(1);
              setDataOrders([]);
              setDataHistory([]);
              setHasMore(true);
              setDate(INITIAL_DATE);
              setDateInput([today, today]);
              handleSetUrlSearchParams('status', target.value, setSearchParams);
            }}
            shadowDesign
          />
        </Div>
      </AnimatedDiv>

      <H5 $mb="32">{renderDateLabelWithToday(from, to)}</H5>

      {status === 'history' ? (
        <OrderHistoryComponent data={dataOrders} dataHistory={dataHistory} />
      ) : (
        dataOrders?.map((data, i) => {
          return <OrderComponent data={data} key={i} index={i} />;
        })
      )}

      {dataOrders.length < 1 && (
        <Div $flex $center $column>
          <Img src={menuItems} alt="no menu items" $width="300px" />
          <H3 $color={lightTheme.primary} $mb="20">
            No Orders!
          </H3>
          <P $bold>You currently don’t have {status} orders.</P>
        </Div>
      )}
      <div ref={ref}></div>

      <OrderDetails />
    </>
  );
};
