import { DateTime } from 'luxon';
import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

export const useClientPayments = create(
  devtools((set) => ({
    clientPayments: [],
    setClientPayments: (value) => set(() => ({ clientPayments: value })),

    setRetriedPayment: (payment) =>
      set((state) => {
        const updatedOrders = state.clientPayments.map((p) => {
          if (payment.id === p.id) {
            return payment;
          }
          return p;
        });

        return {
          ...state,
          clientPayments: updatedOrders,
        };
      }),
  }))
);
