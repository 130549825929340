import {
  ADMIN_VERIFICATIONS_RESTAURANT,
  AdminHeader,
  AdminInput,
  AdminSpinner,
  AnimatedDiv,
  Button,
  ConnectGrubconModal,
  Div,
  DownloadIcon,
  ErrorToast,
  GrubconConnect,
  H3,
  H5,
  Icon,
  ImagePlaceholderIcon,
  ImagePreview,
  Img,
  Logo,
  P,
  ReferralIcon,
  Separator,
  SuccessToast,
  api,
  downloadImage,
  lightTheme,
  providers,
  renderProviderImage,
  useVerifications,
} from '@vgrubs/components';
import { useEffect, useState } from 'react';
import { useMatch, useNavigate } from 'react-router';
import { RejectModal } from './RejectModal';
import { GridProviders } from './VerificationsStyled';
import { KeyValueInfo } from './components/KeyValueInfo';
import { Services } from '../Locations/services/Services';

export const RestaurantDetails = () => {
  // const [modalIsOpen, setModalIsOpen] = useState(false);
  const [grubconConnectModal, setGrubconConnectModal] = useState(false);
  const [loading, setLoading] = useState('');
  const [location, setLocation] = useState('');
  const [percentage, setPercentage] = useState('');
  const [achCustomerId, setAchCustomerId] = useState('');

  const navigate = useNavigate();

  const [rejectModal, setRejectModal] = useState(false);

  const [sip, setSip] = useState(null);
  const [crmApiKey, setCrmApiKey] = useState(null);

  const id = useMatch(ADMIN_VERIFICATIONS_RESTAURANT).params.id;

  const { getCount } = useVerifications((state) => state);

  const isGrubconConnected = location.crmApiKey !== null;

  async function handleVerifyLocation() {
    if (!percentage && percentage < 1) {
      ErrorToast('Please add bracket percentage.');
      return;
    }

    const body = {
      percentage,
      crmApiKey,
      sip,
    };

    if (!sip?.server || !sip?.user || !sip?.password || !sip?.phoneNumber) {
      delete body.sip;
    }

    try {
      setLoading(true);
      const { data } = await api.post(`/v1/locations/${id}/verify`, body);
      SuccessToast(data.message);
      // setModalIsOpen(true);
      getCount();
      navigate(-1);
    } catch (error) {
      ErrorToast(error?.response?.data?.message);
    } finally {
      setLoading(false);
    }
  }

  async function handleGetLocation() {
    const dateNow = new Date();
    try {
      setLoading(true);
      const { data } = await api.get(`/v1/locations/${id}`, {
        params: { from: dateNow, to: dateNow },
      });
      setLocation(data.data);
    } catch (error) {
      ErrorToast(error?.response?.data?.message);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    if (id) {
      handleGetLocation();
    }
  }, []);

  const {
    name,
    address,
    owner,
    phone,
    businessLicense,
    menuSignInProviders,
    city,
    state,
    zipCode,
    country,
  } = location;

  const fullAddress = `${address} ${city} ${state} ${zipCode} ${country}`;

  return (
    <>
      <AdminHeader />
      {loading && <AdminSpinner />}
      {location && (
        <AnimatedDiv>
          <Div
            $flex
            $columnSM
            $gap="50px"
            $gapSM="30px"
            $mt="40"
            $mtSM="20"
            $mb="40"
            $mbSM="20"
            $between
          >
            <Div $flex $alignCenter $gap="30px" $gapSM="16px">
              <Logo width="125" height="125" />
              <Div>
                <H3 $mb="8" $color={lightTheme.primary}>
                  {name}
                </H3>
                <P $m="0">{fullAddress}</P>
              </Div>
            </Div>

            <Div $flex $alignEnd $justifyCenter $column $gap="16px">
              <Button
                $selected
                $gray
                $w="300px"
                onClick={() => setRejectModal(id)}
              >
                Reject Restaurant
              </Button>
              <Button $selected $w="300px" onClick={handleVerifyLocation}>
                Verify Restaurant
              </Button>
            </Div>
          </Div>

          <Div $flex $columnSM $gap="50px" $gapSM="24px" $mt="40" $mtSM="20">
            <Div $width="50%" $widthSM="100%">
              <KeyValueInfo
                k="Owner"
                v={owner?.firstName + ' ' + owner?.lastName}
              />
              <a href={`mailto:${owner?.email}`}>
                <KeyValueInfo k="Email" v={owner?.email} />
              </a>
              <a href={`tel:${phone}`}>
                <KeyValueInfo k="Phone" v={phone} />
              </a>

              <Div $flex $alignCenter $mb="24" $between $mt="24">
                <H3 $m="0">Business License</H3>
                <Icon
                  $pointer
                  onClick={() =>
                    downloadImage(
                      businessLicense,
                      `Agent ${name} Business License`
                    )
                  }
                >
                  <DownloadIcon />
                </Icon>
              </Div>
              <Div
                $box
                $p="24px"
                $pSM="16px"
                $mb="30"
                $mbSM="24"
                $flex
                $justifyCenter
              >
                {businessLicense && <ImagePreview image={businessLicense} />}
                {!businessLicense && (
                  <Div $m="40px 0">
                    <ImagePlaceholderIcon />
                  </Div>
                )}
              </Div>

              <GrubconConnect
                isGrubconConnected={isGrubconConnected}
                setGrubconConnectModal={setGrubconConnectModal}
                setSip={setSip}
                setCrmApiKey={setCrmApiKey}
              />
            </Div>
            <Div $width="60%" $widthSM="100%">
              <Div $flex $columnSM $gap="20px" $mt="40" $mtSM="20">
                <Div $width="50%" $widthSM="100%">
                  <AdminInput
                    label="Bracket Percentage"
                    placeholder="Enter here"
                    value={percentage}
                    onChange={(e) => setPercentage(e.target.value)}
                    type="number"
                    $textCenter
                  />
                </Div>
                <Div $width="50%" $widthSM="100%">
                  <AdminInput
                    tooltip="The GoCardless Customer ID will be used for processing client payments related to outstanding balances on the vDrive credit card. A 2% fee will be applied for each balance top-up. Payments will be automatically charged every Monday and Thursday night."
                    label="ACH Customer ID"
                    placeholder="Enter here"
                    value={achCustomerId}
                    onChange={({ target }) => setAchCustomerId(target.value)}
                    $textCenter
                  />
                </Div>
              </Div>

              {menuSignInProviders.length > 0 && (
                <Div $mt="40" $mtSM="30">
                  <H3 $mb="14" style={{ fontWeight: 400 }}>
                    <b>Providers</b> which the restaurant uses
                  </H3>
                  <GridProviders>
                    {menuSignInProviders.map(({ name }) => (
                      <Provider provider={name} />
                    ))}
                  </GridProviders>
                </Div>
              )}

              <H3 $mb="24" $mt={menuSignInProviders.length > 0 ? '0' : '32'}>
                Onboarding Sales
              </H3>
              <Div $box $p="12px 24px" $flex $between $mb="16">
                <Div>
                  <H5 $mb="0">Cameron Williamson</H5>
                  <P $m="0" $color={lightTheme.lightGray}>
                    Referral
                  </P>
                </Div>
                <Icon>
                  <ReferralIcon />
                </Icon>
              </Div>

              <Services
                setLoading={setLoading}
                id={id}
                salesConfig={location.salesConfig}
                setData={setLocation}
                componentText={`Services that client is interested at`}
              />
            </Div>
          </Div>
        </AnimatedDiv>
      )}
      {/* <Modal
        isOpen={modalIsOpen}
        setIsOpen={setModalIsOpen}
        successImage
        logo={false}
      >
        <H3 $m="0">
          {name} <br />
          <span>Restaurant is Verified!</span>
        </H3>
        <Separator $m="20px 0" />
        <P $mb="24px">Close delivery service provider</P>
        <Button $selected $gray $w="100%">
          Add Providers
        </Button>
        <P $m="12px 0">OR</P>
        <Link to={ADMIN_VERIFICATIONS}>
          <Button $selected $w="100%">
            Continue
          </Button>
        </Link>
      </Modal> */}
      <ConnectGrubconModal
        modalIsOpen={grubconConnectModal}
        setModalIsOpen={setGrubconConnectModal}
        setSip={setSip}
        setCrmApiKey={setCrmApiKey}
        sipForEdit={sip}
        crmApiKeyForEdit={crmApiKey}
        type={isGrubconConnected ? 'edit' : 'add'}
      />
      <RejectModal
        modalIsOpen={rejectModal}
        setModalIsOpen={setRejectModal}
        setLoading={setLoading}
      />
    </>
  );
};

export const Provider = ({ provider }) => {
  if (Object.values(providers).includes(provider.toLowerCase())) {
    return (
      <Div $box $p="12px 20px" $flex $column $alignCenter>
        <Img
          src={renderProviderImage(provider.toLowerCase())}
          $maxWidth="100px"
          $maxHeight="55px"
        />
        <Separator $m="8px 0" />
        <P $bold $center $mb="0" $capitalize>
          {provider}
        </P>
      </Div>
    );
  }
};
