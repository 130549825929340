import {
  ArrowRed,
  Disclaimer,
  Div,
  ErrorToast,
  H5,
  Icon,
  SuccessToast,
  VDriveLogoSmall,
  VGrubsDisabledIcon,
  VGrubsLogoSmall,
  VOrdersLogoSmall,
  api,
  lightTheme,
} from '@vgrubs/components';
import { useState } from 'react';
import { EditServices } from './EditServices';
import { color } from 'framer-motion';

export const Services = ({
  salesConfig,
  setLoading,
  id,
  setData,
  componentText,
}) => {
  const [servicesModal, setServicesModal] = useState(false);
  const [preferences, setPreferences] = useState(false);

  async function handleGetPreferences() {
    try {
      setLoading(true);
      const { data } = await api.get(`/v1/agents/preferences`);
      setPreferences(data.data);
      setServicesModal(true);
    } catch (error) {
      ErrorToast(error?.response?.data?.message);
    } finally {
      setLoading(false);
    }
  }

  return (
    <>
      <Div
        $box
        $gap="20px"
        $flex
        $alignCenter
        $between
        $p="20px 24px"
        onClick={handleGetPreferences}
        $pointer
      >
        <Div>
          <H5 $mb="0">{componentText}</H5>
        </Div>

        <Div $flex $alignCenter $gap="16px">
          {salesConfig?.vDriveService && <VDriveLogoSmall />}
          {salesConfig?.vOrdersService && <VOrdersLogoSmall />}
          {salesConfig?.vGrubsService && <VGrubsLogoSmall />}

          {!salesConfig && (
            <Div $flex $alignCenter $gap="8px">
              <VGrubsDisabledIcon />
              <Disclaimer $m="0" $color={lightTheme.lightGray}>
                No Services
              </Disclaimer>
            </Div>
          )}

          <Icon>
            <ArrowRed />
          </Icon>
        </Div>
      </Div>

      <EditServices
        preferences={preferences}
        salesConfig={salesConfig}
        modalIsOpen={servicesModal}
        setModalIsOpen={setServicesModal}
        setLoading={setLoading}
        id={id}
        setData={setData}
      />
    </>
  );
};
