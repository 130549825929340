import {
  AdminDatePicker,
  AdminHeader,
  AdminInput,
  AdminSelect,
  AnimatedDiv,
  Div,
  H5,
  handleSetUrlSearchParams,
  renderDateLabelWithToday,
  setTimeStartEnd,
  useUIState,
  useClientPayments,
  ErrorToast,
  api,
  AdminSpinner,
  unavailableImage,
  Img,
  H3,
  P,
  moneyAndCoins,
} from '@vgrubs/components';
import { useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Tab } from '../Verifications/VerificationsStyled';
import debouce from 'lodash.debounce';
import { useInView } from 'react-intersection-observer';
import { ClientPaymentComponent } from './components/ClientPaymentComponent';

const statusOptions = [
  { name: 'All Statuses', value: 'allStatuses' },
  { name: 'Initiated', value: 'initiated' },
  { name: 'Failed', value: 'failed' },
  { name: 'Completed', value: 'completed' },
];

const typeOptions = (
  <optgroup label="Select Status">
    {statusOptions.map((r, i) => (
      <option value={r.value} key={i}>
        {r.name}
      </option>
    ))}
  </optgroup>
);

export const ClientPayments = () => {
  const today = new Date();

  const INITIAL_DATE = [
    setTimeStartEnd(today, 'start'),
    setTimeStartEnd(today, 'end'),
  ];

  const [dateInput, setDateInput] = useState([today, today]);
  const { clientPaymentsTab, setClientPaymentsTab } = useUIState(
    (state) => state
  );

  const { clientPayments, setClientPayments, setRetriedPayment } =
    useClientPayments((state) => state);

  const [loading, setLoading] = useState(false);

  const [searchParams, setSearchParams] = useSearchParams();

  const status = searchParams.get('status') ?? null;
  const type = searchParams.get('type') ?? 'vGrubs';
  const from = searchParams.get('from') ?? INITIAL_DATE[0];
  const to = searchParams.get('to') ?? INITIAL_DATE[1];
  const page = Number(searchParams.get('page')) ?? 1;
  const query = searchParams.get('query') ?? null;

  const limit = 20;
  const [hasMore, setHasMore] = useState(true);
  const [ref, inView] = useInView();

  const setDate = (date) => {
    handleSetUrlSearchParams('from', date[0], setSearchParams);
    handleSetUrlSearchParams('to', date[1], setSearchParams);
  };

  const setPage = (page) => {
    handleSetUrlSearchParams('page', page, setSearchParams, true);
  };

  const setStatus = (value) => {
    if (value === 'allStatuses')
      return handleSetUrlSearchParams('status', null, setSearchParams);
    handleSetUrlSearchParams('status', value, setSearchParams);
  };

  const setType = (value) => {
    handleSetUrlSearchParams('type', value, setSearchParams);
  };

  const setQuery = (value) => {
    handleSetUrlSearchParams('query', value, setSearchParams);
  };

  const handleDateChange = (date) => {
    setDateInput(date);
  };

  const handleDateApply = () => {
    setClientPayments([]);

    if (dateInput.length < 2) {
      setDate([
        setTimeStartEnd(dateInput[0], 'start'),
        setTimeStartEnd(dateInput[0], 'end'),
      ]);
      setDateInput([dateInput[0], dateInput[0]]);
    } else {
      setDate([
        setTimeStartEnd(dateInput[0], 'start'),
        setTimeStartEnd(dateInput[1], 'end'),
      ]);
    }
  };

  const handleChange = async (e) => {
    setQuery(e.target.value);
  };

  const debouncedResults = useMemo(() => {
    return debouce(handleChange, 500);
  }, [searchParams]);

  useEffect(() => {
    return () => {
      debouncedResults.cancel();
    };
  });

  async function getOrders(hasMore, page, isFilter) {
    if (!hasMore) return;

    try {
      setLoading(true);
      const { data } = await api.get(`/v1/client-payments`, {
        params: { page, limit, status, from, to, type, query },
      });

      if (data.data.length === limit) {
        setPage(Number(page) + 1);
        setHasMore(true);
      } else {
        setHasMore(false);
      }

      if (isFilter) {
        setClientPayments(data.data);
      } else {
        setClientPayments([...clientPayments, ...data.data]);
      }
      setLoading(false);
    } catch (error) {
      ErrorToast(error?.response?.data?.message);
      setLoading(false);
    }
  }

  async function retryPayment(id) {
    try {
      setLoading(true);
      const { data } = await api.post(`/v1/client-payments/${id}`);

      setRetriedPayment(data.data);
    } catch (error) {
      ErrorToast(error?.response?.data?.message);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    setPage(1);
    setHasMore(true);
    getOrders(true, 1, true);

    return () => {
      setClientPayments([]);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [from, to, status, query, type]);

  useEffect(() => {
    if (inView && dataOrders.length > 0) {
      getOrders(hasMore, page, false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inView]);

  return (
    <>
      {loading && <AdminSpinner />}
      <AdminHeader title={'Client Payments'}>
        <Div $flex $gap="32px" $gapSM="20px">
          <AdminDatePicker
            mode="range"
            value={dateInput}
            onChange={handleDateChange}
            click={handleDateApply}
          />
        </Div>
      </AdminHeader>
      <AnimatedDiv
        $flex
        $alignCenter
        $between
        $columnSM
        $gap="32px"
        $gapSM="24px"
        $p="32px 0"
      >
        <Div $flex $gap="24px" $gapSM="16px">
          <Tab selected={type === 'vGrubs'} onClick={() => setType('vGrubs')}>
            vGrubs
          </Tab>

          <Tab selected={type === 'vDrive'} onClick={() => setType('vDrive')}>
            vDrive
          </Tab>
        </Div>

        <Div $width="300px" $widthSM="100%">
          <AdminSelect
            value={status}
            options={typeOptions}
            onChange={({ target }) => {
              setStatus(target.value);
            }}
            shadowDesign
          />
        </Div>
      </AnimatedDiv>
      <AnimatedDiv
        $flex
        $alignCenter
        $between
        $columnSM
        $gap="32px"
        $gapSM="24px"
        $mb="32"
      >
        <H5 $mb="0">{renderDateLabelWithToday(from, to)}</H5>
        <AdminInput
          maxWidth="500px"
          maxWidthSM="100%"
          search
          placeholder="Search by Client"
          onChange={debouncedResults}
        />
      </AnimatedDiv>
      {clientPayments.map((payment) => {
        return (
          <ClientPaymentComponent
            payment={payment}
            retryPayment={retryPayment}
          />
        );
      })}

      {!loading && clientPayments.length < 1 && (
        <Div $flex $column $alignCenter $mt="150">
          <Img $width="200px" src={moneyAndCoins} $mb="24" />
          <H3 $mb="24" $center>
            No Client Payments
          </H3>
          <P $mb="32" $center>
            No results found for the selected filters.
          </P>
        </Div>
      )}
    </>
  );
};
