import {
  ADMIN_MENU_PROVIDER,
  AdminCustomProviderSelect,
  AdminHeader,
  AdminSpinner,
  Button,
  Div,
  ErrorToast,
  H3,
  H5,
  Img,
  Modal,
  P,
  api,
  providers,
  renderProviderIcon,
  renderProviderName,
  unavailableImage,
} from '@vgrubs/components';
import { useEffect, useState } from 'react';
import { useMatch } from 'react-router';
import { Tab, TabsWrapper } from '../Verifications/VerificationsStyled';
import { Availability } from './MenuProviderSections/Availability/Availability';
import { Categories } from './MenuProviderSections/Categories/Categories';
import { Items } from './MenuProviderSections/Items/Items';
import { Modifiers } from './MenuProviderSections/Modifiers/Modifiers';
import { MenuSimple } from './MenuSimple';

export const MenuProvider = () => {
  const [importMenuModal, setImportMenuModal] = useState(false);
  const [menu, setMenu] = useState(null);
  const [simpleMenu, setSimpleMenu] = useState(null);
  const [loading, setLoading] = useState(false);
  const match = useMatch(ADMIN_MENU_PROVIDER);
  const { pathname, params } = match;
  const [provider, setProvider] = useState(params.provider || 'delivery');
  const [menuTab, setMenuTab] = useState(1);
  const [isNotConnected, setIsNotConnected] = useState(false);

  async function handleGetMenu() {
    try {
      setLoading(true);
      const { data } = await api.get(`/v1${pathname}`);
      setMenu(data.data);
    } catch (error) {
      if (
        error?.response?.data?.message === 'Provider delivery is not connected.'
      ) {
        setIsNotConnected(true);
      } else {
        ErrorToast(error?.response?.data?.message);
      }
    } finally {
      setLoading(false);
    }
  }

  async function handleGetMenuSimple() {
    try {
      setLoading(true);
      const { data } = await api.get(
        `/v1/${params.brandId === '0' ? 'locations' : 'brands'}/${
          params.brandId === '0' ? params.locationId : params.brandId
        }/menu`
      );
      setSimpleMenu(data.data);
    } catch (error) {
      ErrorToast(error?.response?.data?.message);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    setIsNotConnected(false);
    if (provider === providers.DELIVERY && !menu) handleGetMenu();
    if (provider !== providers.DELIVERY && !simpleMenu) handleGetMenuSimple();
  }, [provider]);

  return (
    <div>
      {loading && <AdminSpinner />}
      <AdminHeader title="Menu">
        {/* <HeaderIconWrapper onClick={() => setImportMenuModal(true)}>
          <ImportMenuIcon />
        </HeaderIconWrapper> */}
      </AdminHeader>
      <Div $mt="40" $p="0 0 150px 0">
        <AdminCustomProviderSelect value={provider} onChange={setProvider} />
        {provider === providers.DELIVERY ? (
          <>
            <TabsWrapper>
              <Tab selected={menuTab === 1} onClick={() => setMenuTab(1)}>
                Items
              </Tab>
              <Tab selected={menuTab === 2} onClick={() => setMenuTab(2)}>
                Modifiers
              </Tab>
              <Tab selected={menuTab === 3} onClick={() => setMenuTab(3)}>
                Categories
              </Tab>
              <Tab selected={menuTab === 4} onClick={() => setMenuTab(4)}>
                Availability
              </Tab>
            </TabsWrapper>

            {!isNotConnected && (
              <>
                {menuTab === 1 && (
                  <Items
                    menu={menu}
                    setMenu={setMenu}
                    setLoading={setLoading}
                  />
                )}
                {menuTab === 2 && (
                  <Modifiers
                    menu={menu}
                    setMenu={setMenu}
                    setLoading={setLoading}
                  />
                )}
                {menuTab === 3 && (
                  <Categories
                    menu={menu}
                    setMenu={setMenu}
                    setLoading={setLoading}
                  />
                )}
                {menuTab === 4 && (
                  <Availability
                    menu={menu}
                    setMenu={setMenu}
                    setLoading={setLoading}
                  />
                )}
              </>
            )}
          </>
        ) : (
          <MenuSimple data={simpleMenu} provider={provider} />
        )}
      </Div>

      {isNotConnected && (
        <Div $flex $column $alignCenter>
          <Img $width="180px" src={unavailableImage} $mb="24" />
          <H3 $mb="24" $center>
            Unavailable
          </H3>
          <P $mb="32" $center>
            Provider Delivery.com is not connected.
          </P>
        </Div>
      )}

      <ImportMenuModal
        importMenuModal={importMenuModal}
        setImportMenuModal={setImportMenuModal}
      />
    </div>
  );
};

const ImportMenuModal = ({ importMenuModal, setImportMenuModal }) => {
  const [provider, setProvider] = useState('');

  return (
    <Modal
      header="Import Menu"
      isOpen={importMenuModal}
      setIsOpen={setImportMenuModal}
      logo={false}
      overflow="visible"
    >
      <AdminCustomProviderSelect
        value={provider}
        onChange={setProvider}
        label="Choose provider to copy from"
      />
      <Button $selected $w="100%" $mt="32">
        Import to Delivery.com
      </Button>
    </Modal>
  );
};

export const MenuItemProvider = ({ provider }) => {
  return (
    <>
      <Div $box $p="10px 24px" $flex $alignCenter $between $mb="8">
        <Div $flex $alignCenter $gap="12px">
          <Img src={renderProviderIcon(provider)} $width="35px" />
          <H5 $m="0">{renderProviderName(provider)}</H5>
        </Div>
      </Div>
    </>
  );
};
