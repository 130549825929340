import {
  ADMIN_MENU_PROVIDER,
  Button,
  Div,
  ErrorToast,
  H3,
  Img,
  P,
  SuccessToast,
  api,
  noItems,
} from '@vgrubs/components';
import { useState } from 'react';
import { useMatch } from 'react-router';
import { MenuItemTypeComponent } from './MenuItemComponents';
import { AddItemModal } from './AddItemModal';
import { PausedItemModal } from './PausedItemModal';
import { AddModifierGroupModal } from './AddModifierGroupModal';
import { DeleteItemModal } from './DeleteItemModal';
import { AddNewModifierGroupModal } from '../Modifiers/AddNewModifierGroupModal';

export const removeChildById = (data, parentId, childId) => {
  return {
    ...data,
    results: data.results.map((parent) => {
      if (parent.id === parentId) {
        return {
          ...parent,
          children: parent.children.filter((child) => child.id !== childId),
        };
      }
      return parent;
    }),
  };
};

export const Items = ({ menu, setMenu, setLoading }) => {
  const [addModal, setAddModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [pausedModal, setPausedModal] = useState(false);
  const [addModifierGroupModal, setAddModifierGroupModal] = useState(false);
  const [modifierGroups, setModifierGroups] = useState([]);
  const [deleteModal, setDeleteModal] = useState(false);
  const [addNewModifierGroupModal, setAddNewModifierGroupModal] =
    useState(false);

  const match = useMatch(ADMIN_MENU_PROVIDER);
  const { params } = match;
  const { provider, locationId, brandId } = params;

  async function handleAddItem(
    image,
    name,
    description,
    price,
    categoryId,
    modifierGroupIds,
    emptyForm
  ) {
    const body = {
      name,
      description,
      price,
      categoryId: Number(categoryId),
      modifierGroupIds,
    };

    if (!name) return ErrorToast('Please enter item name');
    if (!image) return ErrorToast('Please upload item image');

    let formData = new FormData();
    if (typeof image !== 'string' && image !== null) {
      formData.append('image', image);
    }
    formData.append('menuItem', JSON.stringify(body));

    try {
      setLoading(true);
      const { data } = await api.post(
        `/v1/menu/${locationId}/item/${brandId}/provider/${provider}`,
        formData,
        {
          headers: { 'content-type': 'multipart/form-data' },
        }
      );
      emptyForm();
      setMenu(data.data);
      SuccessToast(data.message);
      setAddModal(false);
    } catch (error) {
      ErrorToast(error?.response?.data?.message);
    } finally {
      setLoading(false);
    }
  }

  async function handleEditItem(
    image,
    name,
    description,
    price,
    categoryId,
    modifierGroupIds,
    existingItem,
    existingCategoryId,
    emptyForm
  ) {
    const body = {
      name,
      description,
      price,
      categoryId: Number(categoryId),
      modifierGroupIds,
      existingItem,
      existingCategoryId,
    };

    if (!name) return ErrorToast('Please enter item name');
    if (!image) return ErrorToast('Please upload item image');

    let formData = new FormData();
    if (typeof image !== 'string' && image !== null) {
      formData.append('image', image);
    }
    formData.append('menuItem', JSON.stringify(body));

    try {
      setLoading(true);
      const { data } = await api.put(
        `/v1/menu/${locationId}/item/${brandId}/provider/${provider}/update/${existingItem.id}`,
        formData,
        {
          headers: { 'content-type': 'multipart/form-data' },
        }
      );
      emptyForm();
      setMenu(data.data);
      SuccessToast(data.message);
      setEditModal(false);
    } catch (error) {
      ErrorToast(error?.response?.data?.message);
    } finally {
      setLoading(false);
    }
  }

  async function handleDeleteItem() {
    try {
      setLoading(true);
      const { data } = await api.delete(
        `/v1/menu/${locationId}/delete/${brandId}/provider/${provider}/item/${deleteModal.item.id}`
      );
      setMenu(data.data);
      SuccessToast(data.message);
      setDeleteModal(false);
    } catch (error) {
      ErrorToast(error?.response?.data?.message);
    } finally {
      setLoading(false);
    }
  }

  const AddItemButton = () => {
    return (
      <Button $selected onClick={() => setAddModal(true)}>
        Add Item
      </Button>
    );
  };

  const totalChildrenCount = menu?.results?.reduce((count, category) => {
    return count + (category.children ? category.children.length : 0);
  }, 0);

  return (
    <Div>
      <H3 $mb="20">Items {menu?.results && `(${totalChildrenCount})`}</H3>

      {menu?.results?.map((item, i) => (
        <MenuItemTypeComponent
          key={i}
          item={item}
          setPausedModal={setPausedModal}
          onClick={(item) => setEditModal(item)}
          onClickDelete={(id) => setDeleteModal(id)}
          menu={menu}
        />
      ))}

      {menu?.results?.length < 1 && (
        <Div $flex $column $alignCenter>
          <Img $width="180px" src={noItems} $mb="24" />
          <H3 $mb="24" $center>
            No Items
          </H3>
          <P $mb="32" $center>
            This provider currently don’t have <br /> menu items.
          </P>
          <AddItemButton />
        </Div>
      )}

      {menu?.results?.length > 0 && (
        <>
          <Div
            $mobile
            style={{
              position: 'fixed',
              right: '30px',
              bottom: '30px',
              left: '30px',
            }}
            $flex
            $justifyEnd
            $mt="40"
          >
            <AddItemButton />
          </Div>
          <Div
            $desktop
            style={{ position: 'fixed', right: '50px', bottom: '50px' }}
            $flex
            $justifyEnd
            $mt="40"
          >
            <AddItemButton />
          </Div>
        </>
      )}

      <AddItemModal
        menu={menu}
        setMenu={setMenu}
        addModal={addModal}
        setAddModal={setAddModal}
        setAddModifierGroupModal={setAddModifierGroupModal}
        setModifierGroups={setModifierGroups}
        modifierGroups={modifierGroups}
        handleAddItem={handleAddItem}
      />

      <AddItemModal
        menu={menu}
        setMenu={setMenu}
        addModal={editModal}
        setAddModal={setEditModal}
        setAddModifierGroupModal={setAddModifierGroupModal}
        setModifierGroups={setModifierGroups}
        modifierGroups={modifierGroups}
        handleEditItem={handleEditItem}
        edit
      />

      <PausedItemModal
        pausedModal={pausedModal}
        setPausedModal={setPausedModal}
      />
      <AddModifierGroupModal
        addModifierGroupModal={addModifierGroupModal}
        setAddModifierGroupModal={setAddModifierGroupModal}
        menu={menu}
        setModifierGroups={setModifierGroups}
        modifierGroups={modifierGroups}
        setAddNewModifierGroupModal={setAddNewModifierGroupModal}
      />

      <DeleteItemModal
        deleteModal={deleteModal}
        setDeleteModal={setDeleteModal}
        handleDeleteItem={handleDeleteItem}
      />

      <AddNewModifierGroupModal
        addNewModal={addNewModifierGroupModal}
        setAddNewModal={setAddNewModifierGroupModal}
        setMenu={setMenu}
        setLoading={setLoading}
      />
    </Div>
  );
};
