import {
  Disclaimer,
  Div,
  H3,
  H5,
  Img,
  P,
  centsToDollars,
  formatter,
  lightTheme,
  menuItems,
  noItems,
  renderProviderIcon,
} from '@vgrubs/components';

export const MenuSimple = ({ data, name, provider }) => {
  const filteredData = data?.filter((item) =>
    item.providers.some((menuItem) => menuItem.provider === provider)
  );

  return (
    <>
      <>
        <Div $flex $between $m="16px 0" $alignCenter>
          <H5 $m="0">{name}</H5>
          <Disclaimer $m="0">
            Total items: {filteredData?.length || 0}
          </Disclaimer>
        </Div>
        {filteredData?.map((menu, i) => {
          return <MenuComponent key={i} menu={menu} />;
        })}
      </>
      {filteredData?.length < 1 && (
        <Div $flex $column $alignCenter $mt="150">
          <Img $width="180px" src={noItems} $mb="24" />
          <H3 $mb="24" $center>
            No Items
          </H3>
          <P $mb="32" $center>
            This provider currently don’t have <br /> menu items.
          </P>
        </Div>
      )}
    </>
  );
};

const MenuComponent = ({ menu }) => {
  return (
    <Div $box $flex $alignCenter $between $p="16px" $mb="16">
      <Div>
        <H5 $mb="24px">{menu.name}</H5>
        <Div $flex $gap="16px 32px" $wrap>
          {menu.providers.map((p, i) => {
            return (
              <Div $flex $gap="12px" $alignCenter key={i}>
                <Img $width="32px" src={renderProviderIcon(p.provider)} />
                <P $m="0" $color={lightTheme.primary}>
                  {formatter.format(centsToDollars(p.price))}
                </P>
              </Div>
            );
          })}
        </Div>
      </Div>
      <Div></Div>
    </Div>
  );
};
