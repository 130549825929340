import {
  Button,
  Div,
  H5,
  Icon,
  Img,
  Modal,
  P,
  PlusIcon,
  moneyAndCoins,
} from '@vgrubs/components';
import { AgentEarningLevel } from '../../UsersAndAccess/UserDetails/UserDetailsAgent';
import { EarningPlan, EarningPlanVOrdersVDrive } from './EarningPlanComponents';

export const EarningPlans = ({
  earningPlans,
  setEarningPlans,
  setModalIsOpen,
  modalIsOpen,
  earning,
  setAddEarningPlansModal,
  handleEditEarningPlans,
}) => {
  return (
    <Modal
      isOpen={modalIsOpen}
      setIsOpen={setModalIsOpen}
      logo={false}
      header="Earning Plans"
      textAlign={'left'}
    >
      <div>
        {/* <Div $flex $justifyCenter> */}

        <AgentEarningLevel level={earning?.agentLevel} />
        {/* </Div> */}

        <Div $flex $between $alignCenter $m="24px 0 16px 0">
          <H5 $m="0">vGrubs Earning Plans</H5>
          {earning?.vGrubsService && (
            <Icon $pointer onClick={() => setAddEarningPlansModal(true)}>
              <PlusIcon />
            </Icon>
          )}
        </Div>
        {earning?.vGrubsService && (
          <>
            {earningPlans.length > 0 && (
              <>
                {earningPlans
                  .sort((a, b) => {
                    if (a.year === null) {
                      return 1; // Put null year at the end
                    }
                    if (b.year === null) {
                      return -1; // Put null year at the end
                    }
                    return a.year - b.year;
                  })
                  .map((plan, i) => (
                    <EarningPlan
                      plan={plan}
                      setEarningPlans={setEarningPlans}
                      index={i}
                    />
                  ))}
              </>
            )}
          </>
        )}

        {!earning?.vGrubsService && (
          <EarningPlanVOrdersVDrive
            planName={'Earning Plan'}
            amount={0}
            isEnabled={false}
          />
        )}

        {earningPlans.length === 0 && (
          <Div
            // $outlineBox
            $flex
            $column
            $alignCenter
            // $between
            // $p="0 30px"
            // $gap="30px"
          >
            <Img src={moneyAndCoins} alt="no earning plans" $width="150px" />
            <P $mb="16">No vGrubs Earning Plans</P>
            {/* <Div $flex $column $gap="12px" $p="24px 0"> */}
            {/* <Disclaimer $m="0">
                This location currently don't have vGrubs earning plans.
              </Disclaimer> */}
            {/* <Div
                $pointer
                $flex
                $alignCenter
                $gap="12px"
                onClick={() => setAddEarningPlansModal(true)}
              >
                <PlusIcon />
                <P $bold $m="0">
                  <span>Add Earning Plan</span>
                </P>
              </Div> */}
            {/* </Div> */}
          </Div>
        )}

        <H5 $m="24px 0 16px 0">vOrders Earning Plans</H5>

        <EarningPlanVOrdersVDrive
          planName={'SaaS Plan'}
          amount={earning?.vOrdersCommision}
          isEnabled={earning?.vOrdersService}
        />

        <H5 $m="24px 0 16px 0">vDrive Earning Plans</H5>

        <EarningPlanVOrdersVDrive
          planName={'vDrive Sales Fee'}
          amount={earning?.vDriveCommission}
          isEnabled={earning?.vDriveService}
        />

        <Button $selected $w="100%" onClick={handleEditEarningPlans} $mt="24">
          Save
        </Button>
      </div>
    </Modal>
  );
};
